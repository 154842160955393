import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/log/mail'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/log/mail');
  serviceUrl = '/api/eunifikasi/bp/log/mail'
} else {
  service = new CrudService('/bp/log/mail');
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.getBySpt = async(query, sptId)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/spt/${sptId}`,
      query
    })
}

service.history = async(id, query)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/history/${id}`,
      query
    })
}

service.getAll = async(id, query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
    query
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export`,
    query
  })
}

service.getOrganization = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/core/host/organization`,
    query
  })
}

service.exportCurrent = async(data, params)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data,
    config: {
      params: params
    }
  })
}


service.postResendDn = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/bp/dn/resend`,
    data
  })
}

service.postResendLn = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/bp/ln/resend`,
    data
  })
}

export default service;