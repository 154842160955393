import React, {useState, useEffect} from 'react'
import { Button, TextIconSpacing, FontIcon, CircularProgress, Checkbox, Radio, Select, FileInput, Table, TableBody, TableRow, TableCell, TableHeader } from 'react-md'
import t from 'counterpart'
import Hint from '../Hint'
import Flex from '../Flex'
import Modal from '../Modal'
import CustomInput from '../CustomInput'
import validator from 'validatorjs'
import { number, toast } from '../../services'
import { inputTypes, eventTypes, formatTypes } from '../../config/constant'
import Label from '../Label'
import { cloneDeep } from 'lodash'
import { formDefinition } from '../../class'
import LoadingButton from '../LoadingButton'
import config from './DataForm.config'

const getEventName = baseId => (`${baseId}-data-form-event`)
const DataForm = ({
  asDialog                  = false,
  additionalAction          = null,
  baseId                    = 'mpk-data-form-id',
  className                 = '',
  defaultData               = {},
  definitions               = [],
  hintMessage               = '',
  hintIconClassName         = 'mdi mdi-information',
  hintMore                  = '',
  hintShowIcon              = true,
  style                     = {},
  onInitData                = null,
  onBeforeChange            = (key, value) => (value),
  onChange                  = null,
  onSubmit                  = null,
  submitLabel               = t.translate('mpk.column.submit'),
  submitIconClassName       = 'mdi mdi-check',
  usePadding                = true,
  tableForm                 = false, // Custom Unifikasi
  editable                  = true,
  customRules               = null,  // Custom Rules
  watchDefaultData          = true,
  resetDataOnVisible        = true,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [errorValidation, setErrorValidation] = useState(null)

  const getValidationVal = (__validation, __data=data) => {
    return typeof __validation === 'function' ? __validation(__data) : __validation
  }

  const handleSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setErrorValidation(null)

    // Custom Unifikasi
    let rules = {}
    if(tableForm){
      var customDefinitions = []
      definitions.header.map((d)=> {
        customDefinitions.push(d)
      })
      definitions.body.map((d)=> {
        d.map((d2)=> {
          customDefinitions.push(d2)
        })
      })
      for(let def of customDefinitions){
        if(def.inputType === inputTypes.LIST){
          for(let idx = 0 ; idx < data[def.key].length ; idx++){
            for(let def2 of def.definitions){
              if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])//def2.validation
            }
          }
        }else{
          if(def.validation) rules[def.key] = getValidationVal(def.validation)//def.validation
        }
      }
    } else {
      for(let def of definitions){
        if(def.inputType === inputTypes.LIST){
          for(let idx = 0 ; idx < data[def.key].length ; idx++){
            for(let def2 of def.definitions){
              if(def2.validation) rules[`${def.key}.${idx}.${def2.key}`] = getValidationVal(def2.validation, data[def.key][idx])
            }
          }
        }else{
          if(def.validation) rules[def.key] = getValidationVal(def.validation)
        }
      }
    }

    rules = Object.assign(rules, customRules);

    const validation = new validator(data, rules)
    if(validation.fails()) {
      let newErrorValidation = {}
      for(let key of Object.keys(rules)){
        let err = validation.errors.first(key)
        if(err) newErrorValidation[key.replace(/\./g, '_')] = err
      }

      setErrorValidation(newErrorValidation)
      toast.warning(t.translate('mpk.sentence.errorValidation'))
    } else {
      if(onSubmit){
        setLoading(true)
        onSubmit(data, (response, asError=true, autoClose=true) => {
          if(response) {
            if(asError) {
              if(typeof response === 'string') toast.error(response)
              else toast.errorRequest(response);
            } else if(response && typeof response === 'string') toast.success(response)
          }
          if(asDialog && autoClose) handleDialogClose()
          setLoading(false)
        })
      }
    }
  }

  const clearFile = (def, parentKey, indexAtParent) => {
    handleChange(def.key, def.multiline ? [] : null, indexAtParent)
  }

  const handleFile = async (def, e, parentKey, indexAtParent) => {
    let obj
    if(def.multiline){
      obj = []
      for(let file of e.target.files){
        obj.push({file})
      }
    } else {
      obj = e.target.files[0]
    }

    handleChange(def.key, obj, parentKey, indexAtParent)
  }

  const handleChange = async (key, value, parentKey, indexAtParent, def={}) => {
    let obj = {}
    let tmpData = null
    if(def.formatType === formatTypes.MONEY) value = number.format(value.replace(/\D/g, ''))
    if(onBeforeChange) value = await onBeforeChange(key, value, parentKey, (changeOther) => {
      tmpData = changeOther
    })

    if(config.onBeforeChange) value = await config.onBeforeChange(key, value, parentKey)

    if(parentKey && !isNaN(indexAtParent)){
      let parentData = data[parentKey]
      parentData[indexAtParent][key] = value
      if(tmpData) parentData[indexAtParent] = Object.assign(parentData[indexAtParent], tmpData)
      // parentData[indexAtParent] = utils.updateDataField(parentData[indexAtParent], key, value)
      obj[parentKey] = parentData
    } else {
      obj[key] = value
      if(tmpData) obj = Object.assign(obj, tmpData)
      // obj = utils.updateDataField(data, key, value)
    }

    let newObj = Object.assign(data, obj)
    if(onChange) newObj = await onChange(newObj, key, value, parentKey, indexAtParent)
    
    setData(d => ({...d, ...newObj}))
  }

  const handleRemoveListItem = (parentKey, indexAtParent) => {
    let obj = data[parentKey]
    obj.splice(indexAtParent, 1)
    setData(d => ({...d, ...obj}))
  }

  const handleClearList = (parentKey) => {
    let obj = {}
    obj[parentKey] = []
    setData(d => ({...d, ...obj}))
  }

  const handleDialogClose = () => {
    if(resetDataOnVisible) setData(defaultData)
    if(props.onRequestClose){
      props.onRequestClose()
    }
  }

  const render = (def, index, parentKey, indexAtParent=0, elementKey=String(`key-${Math.random()*99999}`)) => {
    const {
      inputType          = null,
      className          = '',
      width              = `100%`,
      style              = {},
      onRelease          = null,
      ...defProps
    } = def

    defProps.label = `${typeof defProps.label === 'function' ? defProps.label(data) : defProps.label}${defProps.required ? '*' : ''}`
    if(onRelease){
      defProps.onBlur = async (e) => {
        let obj = await onRelease(e.target.value, data)
        if(obj) setData(d => ({...d, ...obj}));
      }
    }
    
    const id = `${baseId}-${inputType}-${index}-${parentKey || 'root'}-${indexAtParent}`
    const newClassName = `input mpk-margin-N margin-bottom flex-none ${className}`
    
    let value = def.value
        ? def.value
        : (
          parentKey
            ? data[parentKey][indexAtParent][def.key]
            : data[def.key]
        )

    var errorMessage = errorValidation
    ? (
      parentKey
      ? errorValidation[`${parentKey}_${indexAtParent}_${def.key}`]
      : errorValidation[def.key]
    ) : null

    if(errorMessage) errorMessage = errorMessage.replace(def.key, def.label)

    if(typeof def.show === 'undefined' || (typeof def.show === 'function' ? def.show(data) : def.show)){
      switch(inputType){
        case inputTypes.PRE_DATA:
          return (<pre key={elementKey}>{JSON.stringify(data, null, 2)}</pre>)
        case inputTypes.LIST:
          const {
            key,
            hintMessage       = null,
            hintShowIcon      = true,
            hintIconClassName = null,
            hintMore          = null,
          } = def
          return (
            <div
              className={`mpk-full full-width`}
              // key={elementKey}
            >
              <Label>{def.label}</Label>
              {hintMessage && (
                  <Hint
                    className="mpk-margin-N margin-top margin-bottom"
                    message={hintMessage}
                    showIcon={hintShowIcon}
                    iconClassName={hintIconClassName}
                    more={hintMore}
                  />
                )}
              {Array.isArray(data[key]) && data[key].map((d, i) => (
                <div
                  key={`${baseId}-${def.key}-${i}`}
                  className="mpk-paper mpk-border thin solid dark border-all mpk-padding-N padding-all mpk-margin-N margin-bottom"
                >
                  <Flex
                    className="mpk-full full-width"
                    direction={Flex.properties.direction.ROW}
                    wrap
                  >
                    {def.definitions.map((ldef, ldefi) => (
                      render(ldef, index, def.key, i, `${def.key}-${i}-${ldefi}`)
                    ))}
                  </Flex>
                  { editable && (
                    <Button
                      theme="warning"
                      onClick={() => handleRemoveListItem(def.key, i)}
                      disabled={loading}
                    >
                      <TextIconSpacing
                        icon={<FontIcon iconClassName="mdi mdi-delete"/>}
                      >
                        {t.translate('mpk.column.delete')}
                      </TextIconSpacing>
                    </Button>
                  )}
                </div>
              ))}
              { editable && (
                <Flex
                  align={Flex.properties.align.CENTER}
                  className="mpk-full full-width"
                >
                  <Button
                    themeType="outline"
                    className="mpk-margin-S margin-right"
                    disabled={loading}
                    onClick={() => {
                      if(!Array.isArray(data[key])) data[key] = []
                      data[key].push(def.defaultData ?  cloneDeep(def.defaultData) : {})
                      handleChange(key, data[key])
                    }}
                  >
                    <TextIconSpacing
                      icon={<FontIcon iconClassName="mdi mdi-plus"/>}
                    >
                      {t.translate('mpk.column.add')}
                    </TextIconSpacing>
                  </Button>
                  <Button
                    theme="warning"
                    themeType="outline"
                    disabled={loading}
                    onClick={() => handleClearList(def.key)}
                  >
                    <TextIconSpacing
                      icon={<FontIcon iconClassName="mdi mdi-alert"/>}
                    >
                      {t.translate('mpk.column.clear')}
                    </TextIconSpacing>
                  </Button>
                </Flex>
              )}
            </div>
          )
        case inputTypes.FILE_INPUT:
          return (
            <>
              <div 
                className={`mpk-flex justify-center mpk-full full-width ${newClassName}`}
                key={elementKey}
              >
                <FileInput
                  id={id}
                  className={`flex-none mpk-margin-S margin-right`}
                  value={value}
                  onChange={e => handleFile(def, e, parentKey, indexAtParent)}
                  disabled={loading}
                  theme="primary"
                  themeType={def.multiline
                    ? Array.isArray(value) && value.length > 0 ? 'contained' : 'outline'
                    : [null, undefined, ''].indexOf(value) >= 0 ? 'outline' : 'contained'
                  }
                  buttonType="text"
                  readOnly={!editable}
                  {...defProps}
                  required={false}
                >
                  {defProps.label}
                </FileInput>
                <CustomInput
                  id={`${id}-file-info`}
                  className="flex"
                  theme="outline"
                  value={def.multiline
                    ? Array.isArray(value) ? value.map(d => (d.file.name)).toString() : ''
                    : value ? value.name : ''
                  }
                  required={defProps.required}
                  helpText={defProps.helpText}
                />
                <Button
                  themeType="flat"
                  className="mpk-margin-N margin-left"
                  onClick={() => clearFile(def, parentKey, indexAtParent)}
                >
                  <FontIcon iconClassName="mdi mdi-delete"/>
                </Button>
              </div>
              {errorMessage &&
                <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                  <FontIcon
                    iconClassName="mdi mdi-alert"
                    style={{fontSize:15, color: '#f1420c' }}
                    className="mpk-margin-S margin-right"
                  />
                  {errorMessage}
                </div>
              }
            </>
          )
        case inputTypes.RADIO:
          var defaultValue = "";
          if(def.defaultValue) defaultValue = def.defaultValue
          if(data && data[defProps.key]) defaultValue = (value + "") == data[defProps.key]
          return (
            <Radio
              id={id}
              className={newClassName}
              key={elementKey}
              value={def.value}
              name={def.name}
              checked={defaultValue}
              onChange={e => handleChange(def.key, e.target.value, parentKey, indexAtParent)}
              disabled={loading}
              readOnly={!editable}
              {...defProps}
              style={def.style}
            />
          )
        case inputTypes.CHECKBOX:
          if(!def.width) def.width = '100%'
          var inputForm = [
            <div 
              style={{
                width: def.width,
                ...def.style
              }}
              key={elementKey}
            >
              <Checkbox
                id={id}
                className={`mpk-full full-width ${newClassName}`}
                checked={value}
                defaultChecked={defProps.defaultValue}
                onChange={(e, checked) => handleChange(def.key, e.target.checked, parentKey, indexAtParent)}
                disabled={loading}
                readOnly={!editable}
                {...defProps}
                style={def.style}
              />
              {errorMessage &&
                <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                  <FontIcon
                    iconClassName="mdi mdi-alert"
                    style={{fontSize:15, color: '#f1420c' }}
                    className="mpk-margin-S margin-right"
                  />
                  {errorMessage}
                </div>
              }
            </div>
          ]
          return (
            <>
              {tableForm && 
                <TableCell colSpan={def.colSpan}>
                  {inputForm}
                </TableCell>
              }
              {!tableForm && 
                  <>{inputForm}</>
              }
            </>
          )
        case inputTypes.MULTI_CHECKBOX:
          const isChecked = (d) => {
            let v = typeof d === 'object' ? d.value : d
            return value && value.indexOf(v) >= 0 ? true : false
          }
          return def.options && (
            <div 
              className="mpk-flex direction-column mpk-full full-width"
              key={elementKey}
            >
              {def.label && (
                <Label
                  className={`mpk-padding-N padding-top mpk-margin-XS margin-top ${newClassName}`}
                >
                  {def.label}
                </Label>
              )}
              {def.options.map((d, i) => (
                <Checkbox
                  id={`${id}-${def.key}-${i}`}
                  key={`${id}-${def.key}-${i}`}
                  className={`mpk-full full-width ${newClassName}`}
                  checked={isChecked(d)}
                  onChange={(e, checked) => {
                    if(value){
                      let itemValue = typeof d === 'object' ? d.value : d
                      if(e.target.checked) value.push(itemValue);
                      else value.splice(value.indexOf(itemValue), 1);
                      handleChange(def.key, value, parentKey, indexAtParent)}
                    }
                  }
                  disabled={loading}
                  readOnly={!editable}
                  {...defProps}
                  label={typeof d === 'object' ? d.label : d }
                  style={def.style}
                />
              ))}
            </div>
          )
        case inputTypes.SELECT:
          var inputForm = [
            <div
              className={`mpk-full full-width ${newClassName}`}
              key={elementKey}
              style={{...style, ...{width}}}
            >
              <Select
                id={id}
                className={`mpk-full full-width ${newClassName}`}
                value={value}
                onChange={e => handleChange(def.key, e, parentKey, indexAtParent)}
                disabled={loading || !editable}
                {...defProps}
              />
              {errorMessage &&
                <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ color: '#f1420c', fontSize: 12 }}>
                  <FontIcon
                    iconClassName="mdi mdi-alert"
                    style={{fontSize:12}}
                    className="mpk-margin-S margin-right"
                  />
                  {errorMessage}
                </div>
              }
            </div>
          ]
          return (
            <>
              {tableForm && 
                <TableCell colSpan={def.colSpan}>
                  {inputForm}
                </TableCell>
              }
              {!tableForm && 
                  <>{inputForm}</>
              }
            </>
          )
        
        case inputTypes.REACT_SELECT:
        case inputTypes.AUTOCOMPLETE:
        case inputTypes.DATE:
        case inputTypes.DATETIME:
        case inputTypes.INPUT: // Custom Unifikasi
        case inputTypes.INPUT_MASK:
        case inputTypes.INPUT_MASK_NUMBER:
        case inputTypes.TEXTAREA:
        case inputTypes.DATEPICKER:
        return (
          <>
            {tableForm &&
              <TableCell 
                colSpan={def.colSpan}
                // key={elementKey}
              >
                <CustomInput
                  style={{ marginTop: '16px' }}
                  id={id}
                  inputType={inputType}
                  className={`mpk-margin-M margin-bottom ${newClassName}`}
                  value={value}
                  onChange={e => {
                    if(inputType === inputTypes.REACT_SELECT){
                      var reactSelectValue = e['value']
                      if(def.isMulti){
                        reactSelectValue = e
                      }
                      handleChange(def.key, reactSelectValue, parentKey, indexAtParent, def)
                    } else {
                      handleChange(def.key, e.target.value, parentKey, indexAtParent, def)
                    }
                  }}
                  onAutoComplete={ e => {
                    let val = def.onAutoComplete ? def.onAutoComplete(e) : e.value
                    handleChange(def.key, val, parentKey, indexAtParent, def)}
                  }
                  errorMessage={errorMessage}
                  type={inputType === inputTypes.INPUT
                    ? 'text'
                    : inputType === inputTypes.DATE || inputType === inputTypes.DATETIME || inputType === inputTypes.DATEPICKER
                      ? 'date'
                      : null
                  }
                  containerStyle={{
                    ...{width},
                    ...style
                  }}
                  disabled={loading}
                  readOnly={!editable}
                  {...defProps}
                />
              </TableCell>
            }
            {!tableForm &&
                <CustomInput
                  id={id}
                  inputType={inputType}
                  className={`mpk-margin-M margin-bottom ${newClassName}`}
                  key={elementKey}
                  value={value}
                  onChange={e => {
                    if(inputType === inputTypes.REACT_SELECT){
                      var reactSelectValue = e['value']
                      if(def.isMulti){
                        reactSelectValue = e
                      }
                      handleChange(def.key, reactSelectValue, parentKey, indexAtParent, def)
                    } else {
                      handleChange(def.key, e.target.value, parentKey, indexAtParent, def)
                    }
                  }}
                  onAutoComplete={ e => {
                    let val = def.onAutoComplete ? def.onAutoComplete(e) : e.value
                    handleChange(def.key, val, parentKey, indexAtParent, def)}
                  }
                  errorMessage={errorMessage}
                  type={inputType === inputTypes.INPUT
                    ? 'text'
                    : inputType === inputTypes.DATE || inputType === inputTypes.DATETIME || inputType === inputTypes.DATEPICKER
                      ? 'date'
                      : null
                  }
                  containerStyle={{
                    ...{width},
                    ...style
                  }}
                  disabled={loading}
                  readOnly={!editable}
                  {...defProps}
                />
            }
          </>
          )
        case inputTypes.DIVIDER:
          return (
            <Label
              className={`mpk-padding-N padding-bottom mpk-margin-XS ${newClassName}`}
              key={elementKey}
              {...defProps}
            >{def.label}</Label>
          )
        default:
          return typeof def.render === 'function' ? def.render(data) : def.render
      }
    } else return null;
  }

  const formContent = (
    <div className="mpk-width full-width mpk-margin-N margin-bottom">
      {hintMessage && (
        <Hint
          message={hintMessage}
          showIcon={hintShowIcon}
          iconClassName={hintIconClassName}
          more={hintMore}
          className="mpk-margin-N margin-bottom"
        />
      )}
      <Flex
        className="mpk-full full-width"
        direction={Flex.properties.direction.ROW}
        wrap
      >
        {tableForm &&
          <Table fullWidth={true}>
            <TableHeader>
              <TableRow>
                {definitions.header.map((def, i) => (
                  render(def, i)
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {definitions.body.map((b, i)=> {
                return (
                  <TableRow key={`${b.key}-${i}`}>
                    {b.map((def, i) => (
                      render(def, i)
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        }
        {!tableForm &&
          <>
            {definitions.map((def, i) => (
              render(def, i)
            ))}
          </>
        }
      </Flex>
    </div>
  )

  const form = (
    <form
      id={baseId}
      className={`mpk-form-wrapper mpk-paper ${asDialog ? '' : 'mpk-padding-N padding-all'} ${className}`}
      onSubmit={handleSubmit}
      style={{...{
        width: '100%',
        maxWidth: 800,
        margin: '0 auto'
      }, ...style}}
      {...props}
    >
      {formContent}
      {asDialog ? (
        <button style={{display: 'none'}} type="submit" id={`${baseId}-form-trigger`}/>
      ) : (
        <Flex align={Flex.properties.align.CENTER}>
          {additionalAction && (
            Array.isArray(additionalAction) ? (
              <div className="mpk-margin-S margin-right mpk-flex align-center flex">
                {additionalAction.filter(item => (
                  typeof item.show === 'undefined' 
                    ? true
                    : typeof item.show === 'function' ? item.show(data) : item.show
                )).map(item => (
                  item.render(data)
                ))}
              </div>
            ) : (additionalAction)
          )}

          { editable && (
            <Button
              id={`${baseId}-form-trigger`}
              type="submit"
              themeType="contained"
              theme={loading ? "disabled" : "primary"}
              disabled={loading}
            >
              <TextIconSpacing
                icon={loading ? (
                  <CircularProgress id={`${baseId}-submit-progress`} centered={false}/>
                ) : <FontIcon iconClassName={submitIconClassName}/>}
              >
                {submitLabel}
              </TextIconSpacing>
            </Button>
          )}
        </Flex>
      )}
    </form>
  )

  const child = onSubmit ? form : (
    <div
      className={`mpk-form-wrapper mpk-paper mpk-padding-N padding-all ${className}`}
      onSubmit={handleSubmit}
      style={{...{
        width: '100%',
        maxWidth: 640,
        margin: '0 auto'
      }, ...style}}
      {...props}
    >{formContent}</div>
  )

  // useEffect(() => {
  //   setData(d => ({...d, ...defaultData}))
  // }, [defaultData])

  useEffect(async () => {
    const eventName = getEventName(baseId)
    if(!watchDefaultData) setData(onInitData ? await onInitData(cloneDeep(defaultData)) : cloneDeep(defaultData))
    window.addEventListener(eventName, (e) => {
      const { eventType, eventData } = e.detail
      switch(eventType){
        case eventTypes.SUBMIT:
          const f = document.getElementById(`${baseId}-form-trigger`)
          if(f) f.click()
          break;
        case eventTypes.RESET:
          setData(cloneDeep(defaultData))
          break;
        default:
          break;
      }
    })

    return function cleanup(){
      window.removeEventListener(eventName, () => {}, false)
    }
  }, [])

  useEffect(async () => {
    if(watchDefaultData){
      let __defaultData = onInitData ? await onInitData(cloneDeep(defaultData)) : cloneDeep(defaultData)
      setData((oldData) => {
        return __defaultData
      })
    }
  }, [defaultData])

  return asDialog ? (
    <Modal.Submit
      loading={loading}
      onSubmit={editable ? cb => {
        const f = document.getElementById(`${baseId}-form-trigger`)
        if(f) f.click()
      } : null}
      submitLabel={submitLabel}
      submitIconClassName={submitIconClassName}
      showSubmit={onSubmit && editable}
      {...props}
      onRequestClose={handleDialogClose}
    >
      {form}
    </Modal.Submit>
  ) : child
}

DataForm.inputTypes = inputTypes
DataForm.formatTypes = formatTypes
DataForm.definition = formDefinition
DataForm.LoadingButton = LoadingButton
DataForm.submit = (baseId) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{eventType: eventTypes.SUBMIT}}))
}
DataForm.reset = (baseId) => {
  const eventName = getEventName(baseId)
  window.dispatchEvent(new CustomEvent(eventName, {detail:{eventType: eventTypes.RESET}}))
}

export default DataForm
