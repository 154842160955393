import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from '../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Doss1.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from 'react-md'
import ErrorService from '../../services/errorService'
import Doss1View from './Doss1.form_'
import DoppView from './../DoppDokumenLain/DoppDokumenLain.form_'
import izitoast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import _ from 'lodash'

const Doss1Form = ({ authStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [mergeForm, setMergeForm] = useState({
      doss: null,
      documents: null,
      dopp: null
  })
  const [ploading, setPloading] = useState({ loading: false, message: '' })

  const baseName = `doss1`
  const basePath = `doss1`

  useEffect(() => {
    initData()
  }, [])

  async function initData(){
    try {
      setData({ content: {}, loading: true})
      var spt = JSON.parse(localStorage.getItem('spt'));
      var res = await service.getDefault({
        sptNpwp: spt.npwp,
        sptYear: spt.year,
        sptMonth: spt.month,
        sptRev: spt.rev
      });
      setData({ content: res.data, loading: false })
      setMergeForm(res.data)
    } catch(e){
      ErrorService(e)
      setData({loading:false})
    }
  }

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  async function onSubmit(){
    try {
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var res = {}
      // Mapping DOPP / DOSS
      mergeForm.documents.map((d)=> {
        d.sortCode = parseInt(d.objectCode.split("-").join(""))
      })
      mergeForm.documents = _.sortBy(mergeForm.documents, 'sortCode')
      
      var beforeSave = isNegative(mergeForm.dopp);
      var cekpph = validatePph(mergeForm.dopp)

      if(!beforeSave){
        console.log("gagal")
        izitoast.warning({
          title : t.translate('mpk.column.warning')+" !",
          message : t.translate('words.doppNotNegative')
        })
      }else if(!cekpph){
        izitoast.warning({
          title : t.translate('mpk.column.warning')+" !",
          message : t.translate('words.pphLebihBesar')
        })
      }else{
        Object.keys(mergeForm.dopp).map((d)=> {
          var dataSplit = d.split("_")
          var dataId = dataSplit[0]
          var dataField = dataSplit[1]
          var dataValue = mergeForm.dopp[d]
          var findById = mergeForm.documents.filter((c)=> {
            return c.id == dataId 
          })
          if(dataField == "bruto"){
            findById[0].bruto = dataValue
          } else {
            findById[0].pph = dataValue
          }
        })
        var newObjectSave = Object.assign({}, mergeForm)
        delete newObjectSave.dopp

        // Mapping DOPP / DOSS
        res = await service.saveData(newObjectSave)
        var message = null
        if(res && res.data && res.data.message) message = res.data.message
        if(message){
          izitoast.info({
            message: message
          })
        } else {
          izitoast.info({
            message: t.translate('sentences.tersimpan')
          })
        }
      }
      setPloading({ loading: false, message: `` })
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  var hintMessage = t.translate('words.belumDiPosting')
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(window.location.href.indexOf('open') != -1){
      var c = spt.postingDate
      if(c){
        hintMessage = t.translate('words.tanggalPosting') + spt.postingDate 
      }
    } else {
      hintMessage = ''
    }
  } catch(e){}

  function isNegative(value){
    try{
      var dopp = Object.values(value).filter((d)=>{
        return Math.sign(d) == -1
      })

      if(dopp.length > 0){
        return false
      }

      return true
    }catch(e){}
  }

  function validatePph(value){
    try{
      var cek = []

      Object.keys(value).map((d)=> {
        var dataSplit = d.split("_")
        var dataId = dataSplit[0]
        var dataField = dataSplit[1]
        var dataValue = mergeForm.dopp[d]
        if(dataField == "pph"){
          var databruto = mergeForm.dopp[dataId+"_bruto"]
          if(dataValue > databruto){
            cek.push(dataValue)
          }
        }
      })
      if(cek.length > 0){
        return false
      }
      return true
    }catch(e){}
  }
  
  var actions = [
    {
      label: t.translate('words.reload'),
      iconClassName: 'mdi mdi-reload',
      onClick: initData
    },
    {
      label: 'Simpan',
      iconClassName: 'mdi mdi-floppy',
      onClick: onSubmit
    }
  ]
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(spt.status == 'SUBMIT' || spt.status == 'FINISH'){ 
      actions = [
        {
          label: t.translate('words.reload'),
          iconClassName: 'mdi mdi-reload',
          onClick: initData
        },
      ]
    }
  } catch(e){}
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <FormWrapper
        style={{
          maxWidth: '100%',
          background: '#F3F0ED',
          padding: '0px'
        }}
        className="fullWidth"
        loading={data.loading}
        actions={actions}
        hintMessage={hintMessage}
        baseId={`mod-form-${baseName}`}
        title={t.translate(`modules.${baseName}.title`)}
        definitions={[
          {
            render: (
              <div style={{ width: '100%' }}>
                {mergeForm.doss &&
                  <Doss1View match={match} mergeForm={mergeForm} setMergeForm={setMergeForm} />
                }
                <br />
                {mergeForm.documents && 
                  <DoppView  match={match} mergeForm={mergeForm} setMergeForm={setMergeForm} />
                }
                {/* <br />
                <DoppView  match={match} mergeForm={mergeForm} setMergeForm={setMergeForm} /> */}
              </div>
            )
          }
        ]}
      >
        
      </FormWrapper>
    </>
  )
}

export default inject('authStore', 'envStore')(observer(Doss1Form))
