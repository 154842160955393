import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import ErrorService from '../../services/errorService'
import Inquiry from './Inquiry.form'
import DashboarSpt from './../DashboardSpt/DashboardSpt'
import service from './Dashboard.service'

const DashboardForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: {}})
  const [organization, setOrganization] = useState([])

  const baseName = `dashboard`
  const basePath = `dashboard-spt`

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 200
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  // useEffect(() => {
  //   getOrganizations()
  //   initData()
  // }, [])

  async function initData(){}
  
  return (
    <>
      <DashboarSpt match={match} inSpt={true} />
      {/* <Inquiry match={match}/> */}
    </>
  )
}

export default inject('authStore', 'envStore')(observer(DashboardForm))
