import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './DaftarSpt.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'

const DaftarSptForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [organization, setOrganization] = useState([])

  const baseName = `daftarSpt`
  const basePath = `daftar-spt`

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 200
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        setData({loading: false, content: {}})
        return true
      } else {
        const res = await service.getOne(match.params.id)
        res.data.month = res.data.month + ""
        setData({loading: false, content:res.data})
      }
    }
    if(AppMode.onPremise){
      getOrganizationsOp()
    } else {
      getOrganizations()
    }
    initData()
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise) backTo = `/onpremise/${basePath}`

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.formTitle`)}
      defaultData={data.content}
      onChange={(formData, key, value)=> {
        try {
          if(key == 'organization'){
            // var organizations = organization.filter((d)=>{
            //   return d.label == value
            // })
            // formData.npwp = organizations[0].npwp
            // formData.name = organizations[0].name
            // formData.address = organizations[0].address
            // formData.city = organizations[0].city
            // formData.email = organizations[0].email
            // formData.phone = organizations[0].phone
            // formData.postalCode = organizations[0].postalCode
            formData.npwp = formData.organization.npwp
            formData.name = formData.organization.name
            formData.address = formData.organization.address
            formData.city = formData.organization.city
            formData.email = formData.organization.email
            formData.phone = formData.organization.phone
            formData.postalCode = formData.organization.postalCode
          }
        } catch(e){}
      }}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'organization',
          validation: 'required',
          labelKey: 'label', 
          valueKey: 'label',
          data: organization,
          options: organization,
          async: true,
          defaultOptions: organization,
          loadOptions: async (inputValues)=> {
            if(AppMode.onPremise){
              if(inputValues.length % 2){
                var options = await getOrganizationsOp(inputValues)
                return options
              } else {
                return organization
              }
            } else {
              if(inputValues.length % 2){
                var options = await getOrganizations(inputValues)
                return options
              } else {
                return organization
              }
            }
          },
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.month`),
          key: 'month',
          type: 'text',
          width: '50%',
          validation: 'required',
          options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.year`),
          key: 'year',
          width: '50%',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.npwp`),
          key: 'npwp',
          width: '50%',
          type: 'text',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.name`),
          key: 'name',
          width: '50%',
          type: 'text',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.address`),
          key: 'address',
          type: 'text',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.postalCode`),
          key: 'postalCode',
          type: 'text',
          width: '50%',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.phone`),
          key: 'phone',
          type: 'text',
          width: '50%',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.email`),
          key: 'email',
          type: 'text',
          width: '50%',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.city`),
          key: 'city',
          type: 'text',
          width: '50%',
          validation: 'required',
          disabled: true
        }
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          if(match.params.id == 'new') {
            var formData = Object.assign({}, data)
            delete formData.organization
            res = await service.post(formData)
          } else {
            var formData = Object.assign({}, data)
            delete formData.organization
            res = await service.put(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false)
          var redirectTo = `${backTo}`
          if(AppMode.onPremise){
            redirectTo = `/onpremise/${basePath}`
          }
          history.push(redirectTo)        
        } catch(e){
          ErrorService(e)
          callback("", false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DaftarSptForm))
