import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/Organization')
let serviceUri = '/file'

service.summarySertel = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/sertel/summary`,
    query
  })
}

service.exportSertel = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/export/sertel/cert`,
    query
  })
}

service.exportSertelExpired = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/export/sertel/expired`,
    query
  })
}

service.uploadSertel = async(data)=> {
    return axios({
      method: http.methods.POST,
      url: `/api/sso/cert/upload`,
      data
    })
}
service.addCsv = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUri}/upload/csv`,
    data
  })
}

service.getCertStatus = async(npwp)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/cert/search?npwp=${npwp}`
  })
}

service.getOrganization = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/core/host/organization`,
    query
  })
}

export default service