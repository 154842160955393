import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpPaymentOrder.service'
import { Chip, ListItem, List, Button, DialogContent, Dialog} from 'react-md'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import errorServiceGet from './../../services/errorServiceGet'
import errorService from './../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import servicePo from './../OpLogCetakOrder/OpLogCetakOrder.service'
import LoadingOverlay from 'react-loading-overlay'
import _ from 'lodash'

const OpPaymentOrderSelected = ({
    className       = '',
    showCommandbar  = true,
    history         = '',
    modalStore,
    selectedData    = [],
    match
  }) => {

  const baseId = 'mod-op-payment-order-selected'
  const [basePath, setBasePath] = useState('paymentOrder')

  useEffect(()=> {
  }, [selectedData])

  return (  
    <TableWrapper useFilter={false}
      selectable = {false}
      showFilter = {false}
      useCriteria= {false}
      useFilter = {false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={selectedData}
      defaultSortBy="createdDate"
      sidebar={[]}
      onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            try{
              var data = selectedData
              resolve({ 
                headers: {
                  'x-pagination-count': data.length
                },
                data: data 
              })
            } catch(e){
              resolve()
              errorService(e)
            }
          })
        )
      }}
      columns={[
        {
          label: t.translate('words.code'),
          searchable: true,
          sortable : true,
          key:'code',
          render: item => (item.code)
        },
        {
          label: t.translate('words.idBilling'),
          searchable: true,
          sortable : true,
          key:'idBilling',
          render: item => {
            if(item.idBilling){
              return item.idBilling
            } 
            if(item.errorBilling){
              return item.errorBilling
            } else {
              return '-'
            }
          }
        },
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable : true,
          key:'npwp',
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key: 'name',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.name){
              return item.name
            } else if(item.errorBilling){
              return item.errorBilling
            } else {
              return '-'
            }
          }
        },
        {
          label: t.translate('words.typeTax'),
          searchable: true,
          sortable : true,
          key: 'jpCode',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.jpCode)
        },
        {
          label: t.translate('words.jenisSetoran'),
          searchable: true,
          sortable : true,
          key: 'jsCode',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.jsCode)
        },
        {
          label: t.translate('words.masaPajak'),
          searchable: true,
          sortable : true,
          key: 'bulan1',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            return item.bulan1 + ' - ' + item.bulan2 + ' ' + item.year;
          }
        },
        {
          label: t.translate('words.jumlahSetoran'),
          searchable: true,
          sortable : true,
          key: 'amount',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.amount)
        },
        {
          label: t.translate('words.activeUntil'),
          searchable: true,
          sortable : true,
          key: 'activeUntil',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.activeUntil)
        },
        {
          label: t.translate('words.username'),
          searchable: true,
          sortable : true,
          key: 'additionalUsername',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.additionalUsername)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        } 
      ]}
      actions={[]}
      showCommandbar={false}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpPaymentOrderSelected))
