import React from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { Chip } from '@react-md/chip'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import iziToast from 'izitoast'
import { resolve } from 'bluebird'

const Notifikasi = ({
  className = '',
  showCommandbar = true
}) => {
  var notifikasiAwal = []
  try {
    notifikasiAwal = JSON.parse(localStorage.getItem("notifikasi"))
  }
  catch { }
  return (
    <TableWrapper
      baseId="mod-table-notification"
      title={t.translate('words.notifikasi')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="date"
      columns={[
        {
          label: '',
          searchable: true,
          sortable: true,
          key: 'string',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.string)
        },
        {
          label: 'Status',
          searchable: true,
          sortable: true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.status && item.status == "COMPLETED"){
              return <Chip style={{ background: "#049e51", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
            } 
            if(item.status && item.status == "FAILED"){
              return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
            }
            if(item.status && item.status == "PROCESS"){
              return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
            }else {
              return <Chip>{(item.status+"").toUpperCase()}</Chip>
            } 
          }
        },
        {
          label: 'Type',
          searchable: true,
          sortable: true,
          key: 'type',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.type)
        },
        {
          label: 'Progress',
          searchable: true,
          sortable: true,
          key: 'progress',
          type: TableWrapper.dataTypes.NUMBER,
          render: (item) => {
            if(item.progress > 100) item.progress = 100
            return (
              <div className='progress'>
                {item.status == "FAILED" &&
                  <Progress 
                    percent={100}
                    strokeWidth ={6}
                    status = "error"
                  />
                }
                {item.status !== "FAILED" &&
                  <Progress 
                    percent={item.progress}
                    strokeWidth ={6}
                    theme={{
                      success: {
                        color: '#049e51',
                        trailColor : '#e0e0e0'
                      },
                      active: {
                        color: '#FFC108',
                        trailColor : '#e0e0e0'
                      },
                      default: {
                        color: 'blue',
                        trailColor : '#e0e0e0'
                      }
                    }}
                  />
                }
              </div>
            )
          }
        },
        {
          label: 'Message',
          searchable: true,
          sortable: true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: 'User Id',
          searchable: true,
          sortable: true,
          key: 'userId',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.userId)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', (item) => { 
          localStorage.clear('notification')
          TableWrapper.reload("mod-table-notification")
         }, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', (item) => {
          if (item.url) {
            window.open(item.url)
          } else {
            iziToast.info({
              title: "",
              message: 'File belum tersedia.'
            })
          }
        })
      ]}
      onFetchData={async query => {
        return new Promise((resolve) => {
          var data = []
          try {
            var ambilData = JSON.parse(localStorage.getItem('notification'))
            data = ambilData
          } catch (e) { }
          resolve({
            data: ambilData,
            headers: {
              'x-total-count': ambilData.length
            }
          })
        })
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
      useFilter={false}
    />
  )
}

export default inject('modalStore', 'authStore')(observer(Notifikasi))
