import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { toast } from '../../libs/react-mpk/services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Divider, Button, FontIcon } from 'react-md'
import { DataForm } from '../../libs/react-mpk/components'
import ErrorService from './../../services/errorService'
import SignerService from './../Signer/Signer.service'
import SignerTable from './../Signer/Signer.table'
import Modal from '../../libs/react-mpk/components/Modal'
import moment from 'moment'
import AppMode from '../../AppMode'

const Bp4Form = ({ authStore, envStore, match, navigationStore, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [showModal, setShowModal] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      if(match.params.id == 'new') {
        setData({ loading: false, content: bpform })
      } else {
        setData({ loading: false, content: bpform })
      }
    }
    initData()
    try {
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      var spt = JSON.parse(localStorage.getItem('spt'))
      
      if(spt.status == 'SUBMIT' || spt.status == 'FINISH' && bpform.status == 'FINISH' || bpform.status == 'DELETED' || bpform.status == 'CANCELED'){ 
        setIsHidden(true)
        onSubmit = false
      } else {
        setIsHidden(false)
      }
    } catch(e){}
  }, [])

  function _closeDialog() {
    setShowModal(false)
  }

  var onSubmit = async (data, callback) => {   
    try {
      data.form4valid = true
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      bpform = Object.assign(bpform, data)
      localStorage.setItem('bpform', JSON.stringify(bpform))
      if(bpform.refs.length == 0){
        toast.warning(t.translate('words.b5FromValidation'))
        props.activePanel3()
        callback("", false, false)
        return
      } 
      if(bpform.sptMonth != moment(bpform.date).format("M")){
        toast.warning(t.translate('words.b6FromValidation'))
        callback("", false, false)
        return
      }
      if(bpform.form1valid && bpform.form2valid && bpform.form3valid && bpform.form4valid && bpform.refs.length > 0){
        var sendData = Object.assign({}, bpform);
        delete sendData.form1valid
        delete sendData.form2valid
        delete sendData.form3valid
        delete sendData.form4valid
        sendData.sptMonth = parseFloat(sendData.sptMonth)
        sendData.sptYear = parseFloat(sendData.sptYear)
        sendData.identityAs = parseFloat(sendData.identityAs)
        sendData.bruto = parseFloat(sendData.bruto)
        sendData.pph = parseFloat(sendData.pph)
        sendData.rates = parseFloat(sendData.rates)
        sendData.signAs = sendData.actAs
        sendData.objectCode = sendData.objectCode.split(" - ")[0]
        sendData.signAggree = parseInt(sendData.signAggree)
        delete sendData.actAs
        var res = null
        var backTo = `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/${basePath}`
        if(AppMode.onPremise){
          backTo = `/onpremise/daftar-spt/open/${match.params.sptId}/${basePath}`
        }
        if(match.params.id == 'new') {
          res = await service.post(sendData)
          localStorage.removeItem('bpform')
          navigationStore.redirectTo(backTo)     
        } else {
          res = await service.put(sendData.id, sendData)
          localStorage.removeItem('bpform')
          navigationStore.redirectTo(backTo)     
        }
      } else {
        if(!bpform.form1valid){
          toast.warning(t.translate('words.b1FromValidation'))
          props.activePanel1()
          DataForm.submit('mod-form-bp-1')
        } else if(!bpform.form2valid){
          toast.warning(t.translate('words.b2FromValidation'))
          props.activePanel3()
        } else if(!bpform.form3valid){
          toast.warning(t.translate('words.b3FromValidation'))
          props.activePanel2()
        } else if(!bpform.form4valid){
          toast.warning(t.translate('words.b4FromValidation'))
        }
        callback("", false, false)
      }
    } catch(e){
      ErrorService(e)
      callback("", false, false)
      return
    }
  }
  
  try {
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(spt.status == 'SUBMIT' || spt.status == 'FINISH' && bpform.status == 'FINISH' || bpform.status == 'DELETED' || bpform.status == 'CANCELED'){
      onSubmit = false
    }
  } catch(e){}
  
  try {
    var cMask   = '##.###.###.#-###.###'
    var cValid  = ['required', 'min:15']
    if(bpform.signerId){
      cMask   = '##.###.###.#-###.###'
      cValid  = ['required', 'min:15']
    } else {
      cMask   = '################'
      cValid  = ['required', 'min:16']
    }
  } catch(e){}

  return (
    <>
      <Modal
          disableFocusOnMount={true}
          id="lt-form"
          aria-label="lt-form"
          visible={showModal}
          onRequestClose={_closeDialog}
          style={{
            width: 1024, 
            height: 600
          }}
        >
          <SignerTable match={match} onSearch={true} isHidden={isHidden} setShowModal={setShowModal} setData={setData} data={data} />
      </Modal>
      <Button theme="primary" themeType="contained" style={{ margin: 15, width: '25%' }} onClick={()=> setShowModal(true)} hidden={isHidden}><FontIcon iconClassName="mdi mdi-magnify" />
        {t.translate('words.cariSigner')}
      </Button>
      <FormWrapper
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        definitions={[
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.reference`),
            key: 'reference',
            type: 'text',
          },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.wpkuasa`),
            key: 'actAs',
            type: 'text',
            width: '50%',
            validation: 'required',
            options: [
              { label: t.translate('words.wajibPajak'), value: 'true' },
              { label: t.translate('words.kuasa'), value: 'false' }
            ]
          },
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.tanggal`),
            key: 'date',
            type: 'date',
            width: '50%',
            validation: 'required',
            disabled : match.params.id == 'new' ? false : true
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.namaPenandatangan`),
            key: 'signerName',
            type: 'text',
            width: '50%',
            validation: 'required'
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            mask: cMask,
            maskChar: '_',
            maskValue: 'string',
            label: t.translate(`words.signerIdentity`),
            key: 'signerNpwp',
            type: 'text',
            width: '50%',
            validation: cValid
          },
          {
            render: (
              <div>
                <p style={{margin: "10px"}}><b>{t.translate(`words.sayaMenyetujui`)}</b></p>
              </div>
            )
          },
          {
            inputType: inputTypes.RADIO,
            label: t.translate(`words.bpAgree1`),
            key: 'signAggree',
            type: 'text',
            validation: 'required',
            style: {
              width: '100%'
            },
            value: "0",
          },
          // {
          //   inputType: inputTypes.RADIO,
          //   label: t.translate(`words.bpAgree2`),
          //   key: 'signAggree',
          //   type: 'text',
          //   validation: 'required',
          //   style: {
          //     width: '100%'
          //   },
          //   value: "1",
          //   disabled: true
          // },
          {
            inputType: inputTypes.RADIO,
            label: t.translate(`words.bpAgree3`),
            key: 'signAggree',
            type: 'text',
            validation: 'required',
            style: {
              width: '100%'
            },
            value: "2"
          },
          {
            render: (
              <Divider style={{ marginBottom: 25 }}/>
            )
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.termAndCondition`),
            key: 'syaratDanKetentuan',
            type: 'text',
            value: isHidden,
            validation: 'required'
          },
        ]}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(Bp4Form))
