import React, {useEffect, useState} from 'react'
import { TabsWrapper } from '../../libs/react-mpk/wrapper'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import DataForm from '../../libs/react-mpk/components/DataForm/'
import t from 'counterpart'
import service from './BpArchive.service'
import signerService from './../Signer/Signer.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import BpForm1 from './Bp.form1'
import BpForm2 from './Bp.form2'
import BpForm3 from './Bp.form3'
import BpForm4 from './Bp.form4'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import AppMode from '../../AppMode'

  const BpArchive = ({ 
      className         = '',
      showCommandbar    = true,
      history,
      match
  }) => {
      const [data, setData] = useState({loading: true, content: {}})
      const [panel1, setPanel1] = useState(true)
      const [panel2, setPanel2] = useState(true)
      const [panel3, setPanel3] = useState(true)
      const [panel4, setPanel4] = useState(true)
    
      useEffect(() => {
        async function initData(){
            var spt = {
                npwp: '',
                month: '',
                year: '',
                rev: ''
            }
            let getSigner = {
                name: '',
                npwp: '',
                actAs: 'true',
                signerId: true 
            }
            try {
                var spt = JSON.parse(localStorage.getItem('spt'));
                var getSignerActive = await signerService.getActiveSigner(spt.npwp);
                spt = {
                    npwp: spt.npwp,
                    month: spt.month,
                    year: spt.year,
                    rev: spt.rev
                }
                getSigner = {
                    name: getSignerActive.data.name,
                    npwp: getSignerActive.data.npwp,
                    actAs: getSignerActive.data.actAs,
                    identity: getSignerActive.data.identity,
                }
                if(!getSignerActive.data.identity){
                    getSigner.npwp = getSignerActive.data.nik
                }
            } catch(e){
                console.log(e)
            }
            if(match.params.id == 'new') {
                var bpform = JSON.parse(localStorage.getItem("bpform"))
                localStorage.setItem("bpform", JSON.stringify({
                    sptNpwp: spt.npwp,
                    sptMonth: spt.month + "",
                    sptYear: spt.year + "",
                    sptRev: spt.rev + "",
                    signerName: getSigner.name,
                    signerNpwp: getSigner.npwp,
                    actAs: getSigner.actAs + "",
                    signerId: getSigner.identity,
                    refs: [],
                    address: "-",
                    phone: "0",
                    postalCode: "0",
                    province: "-",
                    city: "-",
                    district: "-",
                    subDistrict: "-",
                    withoutIncome: true,
                    bruto: "0",
                    rates: "0",
                    ratesValue: "0",
                    ...bpform
                }))
                setData({ content: {}, loading: false })
            } else {
                // alamat, telepon,propinsi,kota,kecamatan,kelurahan,dan kode post 
                const res = await service.getOne(match.params.id)
                res.data.signerId = res.data.identity
                res.data.bruto = res.data.bruto + ""
                res.data.pph   = res.data.pph + ""
                res.data.rates = res.data.rates + ""
                res.data.ratesValue = res.data.rates + ""
                res.data.identityAs = res.data.identityAs + ""
                res.data.actAs = res.data.signAs + ""
                res.data.signAggree = res.data.signAggree + ""
                res.data.form4valid = true
                res.data.form3valid = true
                res.data.form2valid = true
                res.data.form1valid = true
                localStorage.setItem("bpform", JSON.stringify(res.data))
                setData({ content: {}, loading: false })
            }
        }
        initData()
      }, [])

      const baseName = `bp`
      const basePath = `bp`

      const activePanel1 = ()=> {
        setPanel1(true);
        setPanel2(false);
        setPanel3(false);
        setPanel4(false);
      }

      const activePanel2 = ()=> {
        setPanel1(false);
        setPanel2(true);
        setPanel3(false);
        setPanel4(false);
      }

      const activePanel3 = ()=> {
        setPanel1(false);
        setPanel2(false);
        setPanel3(true);
        setPanel4(false);
      }

      const activePanel4 = ()=> {
        setPanel1(false);
        setPanel2(false);
        setPanel3(false);
        setPanel4(true);
      }

      var backTo = `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/${basePath}`
      if(AppMode.onPremise) backTo = `/onpremise/daftar-spt=archive/open/${match.params.sptId}/${basePath}`
      
      return (
          <>
            <FormWrapper 
                loading={data.loading}
                backTo={backTo}
                showCommandbar={true}
                baseId={`mod-form-${baseName}`}
                title={t.translate(`modules.${baseName}.formTitle`)}
                style={{
                    maxWidth: '100%'
                }}
                defaultData={data.content}
                definitions={[
                    { 
                        render: (
                            <ExpansionList style={{ width: '100%' }}>
                                <ExpansionPanel expanded={panel1} onClick={()=> activePanel1()} header={ t.translate('words.bpForm1')}>
                                    <BpForm1 match={match} activePanel={activePanel2} />
                                </ExpansionPanel>
                                <br />
                                <ExpansionPanel expanded={panel2} onClick={()=> activePanel2()} header={ t.translate('words.bpForm3')}>
                                    <BpForm3 match={match} activePanel={activePanel3} />
                                </ExpansionPanel>
                                <br />
                                <ExpansionPanel expanded={panel3} onClick={()=> activePanel3()} header={ t.translate('words.bpForm2')}>
                                    <BpForm2 match={match} activePanel={activePanel4} />
                                </ExpansionPanel>
                                <br />
                                <ExpansionPanel expanded={panel4} onClick={()=> activePanel4()} header={ t.translate('words.bpForm4')}>
                                    <BpForm4 match={match} activePanel1={activePanel1} activePanel2={activePanel2} activePanel3={activePanel3}  />
                                </ExpansionPanel>
                            </ExpansionList>
                        )
                    },
                ]}
                // onSubmit={async (data, callback) => {

                // }}
            />
          </>
      )
  }

  export default BpArchive