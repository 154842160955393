import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'
// let service = new CrudService('/spt');

let service = null
var serviceUrl = '/spt'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/spt');
  serviceUrl = '/api/eunifikasi/spt'
} else {
  service = new CrudService('/spt');
}

service.postDashboard = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/summary`,
    data
  })
}

service.getDashboard = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/summary`,
    query
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.postingSpt = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/posting/${id}`,
  })
}

export default service;