import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpLogEmail.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button, Chip} from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import fileSaver from 'file-saver'

const OpLogEmail = ({
  className       = '',
  showCommandbar  = true,
  modalStore,

}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-logEmail'
  const basePath = 'logEmail'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData(){}

  async function exportBulkExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.exportSummary(selectedData)
            console.log(proccedThis)
            window.hahah = proccedThis
            var blob = new Blob([proccedThis.data], {type: 'application/csv' })
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.exportCsv'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        exportBulkExc(item)
        callback()
      }
    })
  }

  async function resendExc(item) {
    try {
      var proses = t.translate('words.resend')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.resend(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function resend(item) {
    modalStore.showConfirm({
      title : t.translate('words.resend'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        resendExc(item)
        callback()
      }
    })
  }

  return (
    <TableWrapper useFilter={false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      selectable={true}
      defaultSortBy="createdDate"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.subject`),
              key: 'subject.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.receipt`),
              key: 'to.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.waiting`),
              key: 'status.in.waiting',
              type: 'text',
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.process`),
              key: 'status.in.process',
              type: 'text',
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.finish`),
              key: 'status.in.finish',
              type: 'text',
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.failed`),
              key: 'status.in.failed',
              type: 'text',
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.company'),
          searchable: true,
          sortable : true,
          key:'company',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.company.nama)
        },
        {
          label: t.translate('words.sender'),
          searchable: true,
          sortable : true,
          key:'from',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.from)
        },
        {
          label: t.translate('words.receipt'),
          searchable: true,
          sortable : true,
          key:'to',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.to)
        },
        {
          label: t.translate('words.subject'),
          searchable: true,
          sortable : true,
          key:'subject',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.subject)
        },
        {
          label: t.translate('words.fileSize'),
          searchable: true,
          sortable : true,
          key:'size',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => {
            try { 
               if(item.size){
                var bytes = item.size
                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                if (bytes == 0) return '0 Byte';
                var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
               } else {
                 return "0 KB"
               }
            } catch(e){
              return "0 KB"
            }
          }
        },
        {
          label: t.translate('words.content'),
          searchable: true,
          sortable : true,
          key:'content',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.content)
        },
        {
          label: t.translate('words.pathFile'),
          searchable: true,
          sortable : true,
          key:'pathFile',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.pathFile)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.status == "PROCESS"){
              return <Chip style={{ background: "#2196F3", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            } else if(item.status == "FINISH"){
              return <Chip style={{ background: "#4CAF50", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else if(item.status == "FAILED"){
              return <Chip style={{ background: "#f44336", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else {
              return <Chip style={{ background: "black", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }
          }
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            let message = item.message
            if (message) {
              message = message.replace('<', '(')
              message = message.replace('>', ')')
            }
            return message
          }
        },
        {
          label: t.translate('words.scheduledDate'),
          searchable: true,
          sortable : true,
          key: 'scheduledDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.scheduledDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.executedDate'),
          searchable: true,
          sortable : true,
          key: 'executedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.executedDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.startDate'),
          searchable: true,
          sortable : true,
          key: 'startDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.startDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.endDate'),
          searchable: true,
          sortable : true,
          key: 'endDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.endDate).format('DD/MM/YYYY hh:mm:ss'))
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', ()=> {exportBulk()}),
        new TableWrapper.action(`${t.translate('words.resend')}`, 'mdi mdi-send', ()=> {resend()}),
      ]}
      itemActions={[]}
      onFetchData={(params)=> {
        params = {
          ...search,
          ...params,
        }
        return (
          new Promise(async (resolve, reject)=> {
            try{
              var type = []
              if(params['status.in.waiting']){
                type.push('WAITING')
              }
              if(params['status.in.failed']){
                type.push('FAILED')
              }
              if(params['status.in.process']){
                type.push('PROCESS')
              }
              if(params['status.in.finish']){
                type.push('FINISH')
              }
              if(params['status.in.finish']||params['status.in.process']||params['status.in.failed']||params['status.in.waiting']){
                params['status.in'] = type
              }
              if(params['status.in']){
                var string2 = ""
                params['status.in'].map((d)=> {
                  string2 += d + ","
                })
                params['status.in'] = string2
              }
            } catch(e){
              delete params['status.in.waiting']
              delete params['status.in.failed']
              delete params['status.in.finish']
              delete params['status.in.process']
              delete params['status.in']
            }
            try {
              await initData()
              var user = JSON.parse(localStorage.getItem('user'))
              var company = user.company.id

              let getPage = null
              getPage = await service.findAll(params, company)
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpLogEmail))
