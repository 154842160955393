import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './ImportBp.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import { Chip, ListItem, List, Button, Dialog, DialogContent } from 'react-md'
import ErrorService from '../../services/errorService'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from '../../services/errorService'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import AppMode from '../../AppMode'

const ImportLogCsv = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore,
  temporaryStore
}) => {

  const basePath = `import-xls-detail`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = `mod-import-xls-detail`
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function importDataExec(item) {
    
  }

  async function importData(item) {
    setDialogItem(item)
    setDialog(true)
  }

  async function downloadLog(item){
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    setTimeout(async ()=> {
      try {
        let res = await service.downloadLog(match.params.id);
        if(res.data.status == 1){
          setPloading({ loading: false, message: `` });
          window.open(res.data.data.url);
        } else {
          setPloading({ loading: false, message: `` });
          iziToast.info({
            message: res.data.message
          })
        }
      } catch (e) {
        setPloading({ loading: false, message: `` });
        errorService(e);
      }
    }, 1000)
  } 
  
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.importDataLogCsv.title')}
        className={className}
        selectable={false}
        defaultData={[]}
        defaultSortBy="line"
        onFetchData={(params)=> {
          params = {
            ...search,
            ...params
          }
          return (
            new Promise(async (resolve, reject)=> {
              try {
                let getPage = null
                var fileId = match.params.id
                getPage = await service.getLog(params, fileId)
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                setData(getPage.data)
                resolve(getPage)
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    localStorage.removeItem(`${baseId}-saved-search`)
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              setSearch(values)
              localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.sheet`),
                key: 'sheet.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.line`),
                key: 'line.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.message`),
                key: 'message.contains',
                type: 'text'
              },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.sheet'),
            searchable: true,
            sortable : true,
            key: 'sheet',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.sheet)
          },
          {
            label: t.translate('words.line'),
            searchable: true,
            sortable : true,
            key: 'line',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.line)
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable : true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.back')}`, 'mdi mdi-arrow-left', (item) => {
            if(AppMode.onPremise){
              if(window.location.href.indexOf('open') != -1){
                history.goBack()
              } else {
                history.goBack()
              }
            } else {
              if(window.location.href.indexOf('open') != -1){
                history.goBack()
              } else {
                history.goBack()
              }
            }
          }, true),
          new TableWrapper.action(`${t.translate('words.importLog')}`, 'mdi mdi-download', (item)=>{ downloadLog(item)}),
        ]}
        itemActions={[
          // new TableWrapper.action(`${t.translate('words.importDetail')}`, 'mdi mdi-history', (item) => {
          //   history.push(`${basePath}/${item.id}/import-detail`)
          // })
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}
export default inject('temporaryStore', 'modalStore')(observer(ImportLogCsv))