import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './DaftarSpt.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'
import { Button, CardContent, Card, Chip, FontIcon, Dialog, DialogContent } from 'react-md'
import OrganizationView from './../Organization/OrganizationPicker.table'
import OpOrganizationTable from '../OpOrganization/OpOrganizationPicker.table'
import iziToast from 'izitoast'

const DaftarSptExport = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: { type: "0", all: false }})
  const [organization, setOrganization] = useState([])
  const [dialogOrg, setDialogOrg] = useState(false);
  const [showOrg, setShowOrg] = useState(true);
  const [hasSummarySptBunifikasiNtte, setHasSummarySptBunifikasiNtte] = useState(false)
  const [hasSummaryRekapNtpnNtte, setHasSummaryRekapNtpnNtte] = useState(false)
  const [hasSummaryBp, setHasSummaryBp] = useState(false)
  const [hasSummarySptBunifikasiNtteCore, setHasSummarySptBunifikasiNtteCore] = useState(false)
  const [hasSummaryRekapNtpnNtteCore, setHasSummaryRekapNtpnNtteCore] = useState(false)
  const [hasSummaryBpCore, setHasSummaryBpCore] = useState(false)

  const baseName = `daftarSptExport`
  const basePath = `daftar-spt`

  useEffect(async () => {
    try {
      if(AppMode.onPremise){
        var getAppInfo = await service.getAppInfo()

        if (getAppInfo) {
          let appInfoData = getAppInfo.data

          if (appInfoData) {
            if (appInfoData.summarySptBunifikasiNtte) {
              setHasSummarySptBunifikasiNtte(true)
            }

            if (appInfoData.summaryRekapNtpnNtte) {
              setHasSummaryRekapNtpnNtte(true)
            }

            if (appInfoData.summaryBp) {
              setHasSummaryBp(true)
            }
          }
        }
      } else {
        let getAppInfoCore = await service.getAppInfoCore()

        if (getAppInfoCore) {
          let appInfoDataCore = getAppInfoCore.data

          if (appInfoDataCore) {
            if (appInfoDataCore.summarySptBunifikasiNtte) {
              setHasSummarySptBunifikasiNtteCore(true)
            }

            if (appInfoDataCore.summaryRekapNtpnNtte) {
              setHasSummaryRekapNtpnNtteCore(true)
            }

            if (appInfoDataCore.summaryBp) {
              setHasSummaryBpCore(true)
            }
          }
        }
      }
    } catch (e) { }
  }, [])

  var backTo = `/product/company/${match.params.companyId}/daftar-spt`
  if(AppMode.onPremise) backTo = `/onpremise/${basePath}`

  let definitions = [
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export1`),
      key: 'type',
      name: 'type',
      value: '0',
      validation: 'required',
      style: {
        width: '20%'
      }
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export2`),
      key: 'type',
      name: 'type',
      value: '1',
      validation: 'required',
      style: {
        width: '20%'
      }
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export3`),
      key: 'type',
      name: 'type',
      value: '2',
      validation: 'required',
      style: {
        width: '20%'
      }
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export5`),
      key: 'type',
      name: 'type',
      value: '3',
      validation: 'required',
      style: {
        width: '20%'
      }
    },
  ]

  if (hasSummarySptBunifikasiNtte || hasSummarySptBunifikasiNtteCore) {
    definitions.push({
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export7`),
      key: 'type',
      name: 'type',
      value: '5',
      validation: 'required',
      style: {
        width: '20%'
      }
    })
  }

  if (hasSummaryRekapNtpnNtte || hasSummaryRekapNtpnNtteCore) {
    definitions.push({
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export8`),
      key: 'type',
      name: 'type',
      value: '6',
      validation: 'required',
      style: {
        width: '20%'
      }
    })
  }

  definitions.push({
    inputType: inputTypes.RADIO,
    label: t.translate(`words.export9`),
    key: 'type',
    name: 'type',
    value: '7',
    validation: 'required',
    style: {
      width: '20%'
    }
  })

  if (hasSummaryBp || hasSummaryBpCore) {
    definitions.push({
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export10`),
      key: 'type',
      name: 'type',
      value: '8',
      validation: 'required',
      style: {
        width: '20%'
      }
    })

    definitions.push({
      inputType: inputTypes.RADIO,
      label: t.translate(`words.export11`),
      key: 'type',
      name: 'type',
      value: '9',
      validation: 'required',
      style: {
        width: '20%'
      }
    })
  }

  definitions.push(
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month1`),
      key: 'startMonth',
      type: 'text',
      width: '50%',
      validation: 'required',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month2`),
      key: 'endMonth',
      type: 'text',
      width: '50%',
      validation: 'required',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.year1`),
      key: 'startYear',
      width: '50%',
      type: 'text',
      validation: 'required',
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.year2`),
      key: 'endYear',
      width: '50%',
      type: 'text',
      validation: 'required',
    },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.allNpwp`),
      key: 'all',
      width: '100%',
      type: 'text',
      validation: 'required',
    },
    {
      show: showOrg,
      render: (
          <div style={{width: '100%'}}>
            <Button themeType="outline" theme="primary" onClick={(e)=> setDialogOrg(true) }>{t.translate("words.pilihOrganisasi")}</Button>
            <Button themeType="outline" theme="error" style={{ marginLeft: 10 }} onClick={(e)=> setOrganization([])} >{t.translate("words.hapusOrganisasi")}</Button>
            <Card style={{width: '100%', marginTop: '25px'}}>
              <CardContent>
                {organization.length > 0 &&
                <>
                  {organization.map((d, i)=> {
                    return <Chip onClick={()=> {
                      var organizationFilter = organization.filter((d)=> {
                        return d.id != d.id
                      })
                      setOrganization(organizationFilter)
                    }} style={{ margin: 5 }} theme="outline" rightIcon={<FontIcon>delete</FontIcon>}>{d.npwp} - {d.name}</Chip>
                  })}
                </>
                }
              </CardContent>
            </Card>
          </div>
      )
    },
    {
      render: (
          <div>
            <Dialog
                visible={dialogOrg}
                onRequestClose={(e)=> setDialogOrg(false)}
                style={{
                  width: 1024,
                  height: 600,
                }}
            >
              <DialogContent>
                {!AppMode.onPremise &&
                <OrganizationView setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                }
                {AppMode.onPremise &&
                <OpOrganizationTable setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                }
              </DialogContent>
            </Dialog>
          </div>
      )
    }
  )

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.formTitle`)}
      defaultData={data.content}
      style={{
        maxWidth: '100%'
      }}
      onChange={(formData, key, value)=> {
        if(key == 'all' && value){
          setOrganization([])
          setShowOrg(false)
        } else {
          try {
            if(formData.all == true){
              setShowOrg(false)
            } else {
              setShowOrg(true)
            }
          } catch(e){
            setShowOrg(true)
          }
        }
      }}
      definitions={definitions}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          var exportType = data.type
          var formData = Object.assign({}, data)
          var npwps = []
          organization.map((d)=> {
            npwps.push(d.npwp)
          })
          delete formData.type
          delete formData.all
          formData.npwps = npwps
          if(formData.npwps.length == 0 && !data.all){
            iziToast.info({
              title: `${t.translate('words.Info')}`,
              message: `${t.translate('words.warningOrg')}`
            })
            callback("", false, false)
          } else {
            if(exportType == "0"){
              if(AppMode.onPremise){
                let res = await service.downloadAllXlsSearchOP(formData, data.all)
              } else {
                let res = await service.downloadAllXlsSearch(formData, data.all)
              }
            } else if(exportType == "1"){
              if(AppMode.onPremise){
                let res = await service.downloadAllXlsDetailSearchOP(formData, data.all)
              } else {
                let res = await service.downloadAllXlsDetailSearch(formData, data.all)
              }
            } else if(exportType == "3"){
              if(AppMode.onPremise){
                let res = await service.downloadAllXlsSummarySPTDetailSearchOP(formData, data.all)
              } else {
                let res = await service.downloadAllXlsSummarySPTDetailSearch(formData, data.all)
              }
            } else if(exportType == "5"){
              if(AppMode.onPremise){
                let res = await service.downloadSummaryVIISearchOP(formData, data.all)
              } else {
                let res = await service.downloadSummaryVIISearchCore(formData, data.all)
              }
            } else if(exportType == "6"){
              if(AppMode.onPremise){
                let res = await service.downloadSummaryVIIISearchOP(formData, data.all)
              } else {
                let res = await service.downloadSummaryVIIISearchCore(formData, data.all)
              }
            } else if(exportType == "7"){
              if(AppMode.onPremise){
                let res = await service.downloadSummaryIXSearchOP(formData, data.all)
              } else {
                let res = await service.downloadSummaryIXSearchCore(formData, data.all)
              }
            } else if(exportType == "8"){
              if(AppMode.onPremise){
                let res = await service.downloadSummaryXSearchOP(formData, data.all)
              } else {
                let res = await service.downloadSummaryXSearchCore(formData, data.all)
              }
            } else if(exportType == "9"){
              if(AppMode.onPremise){
                let res = await service.downloadSummaryXISearchOP(formData, data.all)
              } else {
                let res = await service.downloadSummaryXISearchCore(formData, data.all)
              }
            } else {
              if(AppMode.onPremise){
                let res = await service.downloadAllXlsSummarySPTSearchOP(formData, data.all)
              } else {
                let res = await service.downloadAllXlsSummarySPTSearch(formData, data.all)
              }
            }
            var proses = t.translate('words.downloadExportBp')
            callback("", false, false)
            iziToast.success({
              timeout: 5000,
              title: proses,
              message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
            })
            if(AppMode.onPremise){
              history.goBack()
            } else {
              history.push(`/product/company/${match.params.companyId}/log-export`)
            }
          }
        } catch(e){
          ErrorService(e)
          callback("", false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DaftarSptExport))
