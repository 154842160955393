import React, { useEffect } from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import Spt from './Spt.routes'
import Company from './Company.routes'
import Index from './Index.routes'
import { autorun, toJS, values } from 'mobx';
import { inject, observer } from 'mobx-react'
import { Client, Message } from '@stomp/stompjs'
import iziToast from 'izitoast'
import AppMode from './../../AppMode'
import { Notifikasi } from '../../modules'

const Product = ({ history, navigationStore, authStore }) => {
  if (window.location.pathname === '/product')
    setTimeout(() => navigationStore.redirectTo('/product/index'), 500)

  useEffect(() => {
    var userId = ""
    autorun(() => {
      var authStores = toJS(authStore)
      userId = authStores.user.id
      if (userId) {
        activeWebSocket(userId)
      }
    })
    if (window.location.href.indexOf('index') != -1) {
      try {
        document.getElementsByClassName('mpk-margin-S margin-bottom mpk-full full-width')[0].style.display = "none"
      } catch (e) {
        console.log()
      }
    }
  }, [])

  async function activeWebSocket(userId) {
    try {
      var brokerURL = `${AppMode.wsUrl}?username=${userId}`;
      var client = new Client({
        brokerURL: brokerURL,
        debug: function (str) {
          // console.log('WS debug: ', str)
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000
      });
      var connected = false

      client.onConnect = () => {
        connected = true
        // console.log("ON CONNECTED BOSS")
        client.subscribe('/user/topic/notification', (message) => {
          if (message.body) {
            // console.log("RECIEVED MESSAGE", message.body)
            var pesan = JSON.parse(message.body)
            try {
              var notifikasiAwal = []
              try {
                if (localStorage.getItem("notification")) {
                  notifikasiAwal = JSON.parse(localStorage.getItem("notification"))
                }
              } catch (e) {
                // console.log(e, "ERRRO CUK")
              }
              notifikasiAwal.push(pesan)
              localStorage.setItem("notification", JSON.stringify(notifikasiAwal))
            } catch (e) {
              // console.log(e, "ERRRO CUK")
            }
            iziToast.show({
              theme: 'dark',
              title: 'Notifikasi',
              message: `${pesan.type.split('_').join(' ')} telah di proses ${parseInt(pesan.progress)} dengan status ${pesan.status}`,
              buttons: [
                ['<button>Download</button>', function (item, toast) {
                  if (pesan.url) {
                    window.open(pesan.url)
                  } else {
                    iziToast.info({
                      title: "",
                      message: 'File belum tersedia.'
                    })
                  }
                }, true],
              ],
            });
          } else {
            // console.log("NOT RECIVING MESSAGE")
          }
        })
      }

      client.onDisconnect = () => {
        connected = false
        // console.log("ON DISCONECTED BOSS")
      }

      client.onStompError = (frame) => {
        console.error('WS: Broker reported error: ' + frame.headers['message']);
        console.error('WS: Additional details: ' + frame.body);
      }

      client.beforeConnect = () => {
        // console.log(">>> Initialize Connection to STOMP")
      }

      // console.log(connected)

      client.activate();
    } catch (e) {
      // console.log(e, "WEB SOCKET ERROR")
    }
  }

  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/product/company/:companyId/daftar-spt/open/:sptId'
          render={props => (
            <Spt {...props} />
          )}
        />
        <Route
          path='/product/company/:companyId'
          render={props => (
            <Company {...props} />
          )}
        />
        <Route
          path='/product/index'
          render={props => (
            <Index {...props} />
          )}
        />
      </Switch>
    </Router>
  )
}

export default inject('navigationStore', 'authStore')(observer(Product))