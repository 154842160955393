import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/ss2'
let serviceObject = '/bp/object'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/ss2');
  serviceUrl = '/api/eunifikasi/bp/ss2'
  serviceObject = '/api/eunifikasi/bp/object'
} else {
  service = new CrudService('/bp/ss2');
}

service.exportCurrent = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}

service.getTaxObjectCode = async(type)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceObject}?size=160`,
  })
}

service.history = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary`,
    query
  })
}

service.summary = async(query) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/summary`,
    query
  })
}

service.getReupload = async(id)=> {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/reupload/${id}`,
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

export default service;