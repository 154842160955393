import React, { useEffect, useState } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { http } from '../../libs/react-mpk/services';
import { sso } from '../../libs/react-mpk/hocs'
import t from 'counterpart'
import { List, ListItem, FontIcon } from 'react-md'
import { autorun, toJS } from 'mobx';
import { DaftarSptExportForm,DashboardSpt, DashboardSample, TableSample, FormSample, ScheduleEmailFormView, EmailConfigFormView, LogExportView, LogExportForm, LogEmailView, LogEmailHistoryView, ImportEssp, ImportLogCsv, ImportBp, ImportDossDopp, ImportSetorSendiri, ImportSspPbk, EbillingWidget, DataEsspView, DataEsspForm, Bp26View, BpHistory, PbkHistory, Bp26History, LawanTransaksiForm, Doss2HistoryView, LawanTransaksiView, BpView, Doss2View, DaftarSptView, DaftarHistorySpt, DaftarSptForm, SspView, SspForm, SspHistory, PbkView, PbkForm, SignerForm, SignerView, DaftarBpeView, ImportData, EksporData, OrganizationForm, UserForm, ImportLog, User, Organization, CetakInduk, DaftarSptBulkForm, DaftarCetakIndukForm, Notifikasi, DashboardSertelView, ApprovalFlowTable, ApprovalFlowForm} from '../../modules'
import axios from 'axios'
import _ from 'lodash'
import PermissionConstant from '../../modules/PermissionConstant'
import AppMode from '../../AppMode'
import UploadSertelTable from '../../modules/UploadSertel/UploadSertel.table';
import BpBulkForm from '../../modules/Bp/BpBulk.form';
import Bp26BulkForm from '../../modules/Bp26/Bp26Bulk.form';
import service from '../../modules/DaftarSpt/DaftarSpt.service'
import { Client, Message } from '@stomp/stompjs'
import iziToast from 'izitoast'
import EmailForm from '../../modules/Email/Email.form';
import errorService from '../../services/errorService';
import LogEmailInfoTable from '../../modules/LogEmail/LogEmail.info.table';

const Company = ({match, history}) => {
  const historys = toJS(history)
  var companyId = match.params.companyId
  localStorage.setItem("cpId", companyId)
  
  var sptId = match.params.sptId
  var spt = {
    month: '-',
    year: '-',
    rev: '-'
  }
  try {
    // alert("OKOKOKO")
    var getspt = JSON.parse(localStorage.getItem('spt'))
    if(getspt){
      spt.year  = getspt.year
      spt.month = getspt.month
      spt.rev   = getspt.rev
    }
  } catch(e){
    console.log(e, 'getSpt')
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  useEffect(async ()=> {
    // me:'/api/sso/company/:companyId/me'
    try {
      var token = getCookie("MPK_ACCESS_TOKEN")
      let res = await axios.get(`/api/sso/company/${companyId}/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      var meData = res.data
      var permission = []
      meData.company.subscriptions.map((d)=> {
        permission = [
          ...permission,
          ...d.permission
        ]
      })
      var permission = _.uniqBy(permission, "name")
      var resourceUri = []
      var constantUri = []
      permission.map((d)=> {
        resourceUri.push(d.method+"::"+d.resourceUri)
        constantUri.push(d.method+"_"+d.name.split(" ").join("_")+" : "+d.method+"::"+d.resourceUri)
      })
      var resourceUri = JSON.stringify(resourceUri)
      var constantUri = JSON.stringify(constantUri)
      localStorage.setItem("rpUrl", resourceUri)
    } catch(e){}

    //getAppInfo
    getAppInfo()
  }, [])

  var sidebarMenu = [
    {
      "label":`${t.translate('menu.mainMenu')}`
    },
    {
      "label":`${t.translate('menu.dashboard')}`,
      "children":[
        {
          "label":`${t.translate('modules.dashboard.title')}`,
          "index":"dashboard",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/dashboard-spt`,
          "childPath":[]
        }
      ]
    },
    {
      "label": `${t.translate('menu.mainMenu')}`,
      "children":[
        // {
        //   "label":`${t.translate('modules.uploadSertel.title')}`,
        //   "index":"daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak",
        //   "resourceUri":"",
        //   "iconClassName":"mdi mdi-layout",
        //   "path":`/product/company/${companyId}/upload-sertel`,
        //   "childPath":[]
        // },
        {
          "label":`${t.translate('modules.daftarSpt.title')}`,
          "index":"daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/daftar-spt`,
          "childPath":[]
        },
        {
          "label":`${t.translate('modules.bp.menuTitle')}`,
          "index":"daftar-bp, bp, dn, dalam, negeri, dalam-negeri, bukti, potong",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/bp`,
          "childPath":[]
        },
        {
          "label":`${t.translate('modules.bp26.menuTitle')}`,
          "index":"daftar-bp-26, bp, ln, luar, negeri, luar-negeri, bukti, potong",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/bp26`,
          "childPath":[]
        },
        {
          "label":`${t.translate('modules.doss2.menuTitle')}`,
          "index":"daftar-pph-setor-sendiri, daftar, pph, setor, sendiri, ss",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/doss2`,
          "childPath":[]
        },
        {
          "label":`${t.translate('menu.ssp')}`,
          "index":"ssp, daftar, setoran, surat",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/ssp`,
          "childPath":[]
        },
        {
          "label":`${t.translate('menu.pbk')}`,
          "index":"pbk, daftar, pajak, pemindahbukuan, buku, pindah",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/pbk`,
          "childPath":[]
        },
        {
          "label":`${t.translate('modules.daftar-bpe.title')}`,
          "index":"daftar-bpe, e, elektronik, bukti, penerimaan, bpe",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/daftar-bpe`,
          "childPath":[]
        }
      ]
    },
    {
      "label": `${t.translate('menu.import')}`,
      "children":[
        {
          "label":`${t.translate('menu.importBpExcel')}`,
          "index":"import, bukti potong",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/import`,
          "childPath":[]
        },
        {
          "label":`${t.translate('menu.importCsv')}`,
          "index":"import, csv",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/import-bp`,
          "childPath":[]
        },
      ]
    },
    {
      "label": `${t.translate('menu.billing')}`,
      "children":[
        {
          "label":`${t.translate('menu.dataEssp')}`,
          "index":"data essp, daftar ssp",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/essp`,
          "childPath":[]
        },
        {
          "label":`${t.translate('menu.billingWidget')}`,
          "index":"billing, e-billing, widget, payment, order",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/ebilling-widget`,
          "childPath":[]
        }
      ]
    },
    {
      "label": `${t.translate('menu.logData')}`,
      "children": [
        {
          "label":`${t.translate('modules.logDataEmail.title')}`,
          "index":"email, log",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/log-email`,
          "childPath":[]
        },
        {
          "label":`${t.translate('modules.log-export.title')}`,
          "index":"export, log",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/log-export`,
          "childPath":[]
        },
      ]
    },
    {
      "label": `${t.translate('menu.setting')}`,
      "children": [
        {
          "label":`${t.translate('modules.lawanTransaksi.title')}`,
          "index":"lawan-transaksi, pengaturan, transaksi, lawan, lt",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/lawan-transaksi`,
          "childPath":[]
        },
        {
          "label":`${t.translate('modules.signer.title')}`,
          "index":"tanda, penandatangan, tangan, signer, tanda tangan",
          "resourceUri":"",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/signer`,
          "childPath":[]
        },
        {
          "label": `${t.translate('modules.organization.title')}`,
          "index": "organisasi, org, pengguna",
          "resourceUri": "",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/organization`,
          "childPath": []
        },
        {
          "label": `${t.translate('menu.email')}`,
          "index": "setting, email",
          "resourceUri": "",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/email`,
          "childPath": []
        },
        // {
        //   "label": `${t.translate('menu.scheduleEmail')}`,
        //   "index": "schedule, email",
        //   "resourceUri": "",
        //   "iconClassName":"mdi mdi-layout",
        //   "path":`/product/company/${companyId}/email-schedule`,
        //   "childPath": []
        // },
        // {
        //   "label": `${t.translate('menu.flowApproval')}`,
        //   "index": "flowApproval",
        //   "resourceUri": "",
        //   "iconClassName":"mdi mdi-layout",
        //   "path":`/product/company/${companyId}/flow-approval`,
        //   "childPath": []
        // },
      ]
    },
  ]

  // Handle Permission
  try {
    if(!AppMode.onPremise){
      var newActions = Object.assign([], sidebarMenu)
      var hasPermission = JSON.parse(localStorage.getItem("rpUrl"))
      var filterHasAddPermission = hasPermission.filter((d)=> {
        return d == PermissionConstant["GET_SPT_Service_GET_SPT_Page"]
      })
      if(filterHasAddPermission.length > 0){
        sidebarMenu = sidebarMenu.map((d)=> {
          var newD = d.children.filter((c)=> {
            return c.label != "Daftar SPT"
          })
          console.log(newD)
          // return d.iconClassName != "mdi mdi-plus"
        })
      }
    }
  } catch(e){}
  // Handle Permission

  //Handle Permission Approval
  async function getAppInfo(){
    
  }
  try {
    var hasPermission = []
    try { hasPermission = JSON.parse(localStorage.getItem("rpUrl")) } catch(e){}
    if(hasPermission.length > 0){
      var filter = hasPermission.filter((url)=> { return url == "GET::/approval" }) 
      if(filter.length > 0){
        var sidebarMenuIndex = sidebarMenu.length - 1
        sidebarMenu[sidebarMenuIndex].children.push({
          "label": `${t.translate('menu.flowApproval')}`,
          "index": "flowApproval",
          "resourceUri": "",
          "iconClassName":"mdi mdi-layout",
          "path":`/product/company/${companyId}/flow-approval`,
          "childPath": []
        })
      }
    }
    // {
    //   "label": `${t.translate('menu.flowApproval')}`,
    //   "index": "flowApproval",
    //   "resourceUri": "",
    //   "iconClassName":"mdi mdi-layout",
    //   "path":`/product/company/${companyId}/flow-approval`,
    //   "childPath": []
    // },
    // var appInfo = await service.getAppInfoCore()

    // if(!AppMode.onPremise){
    //   if (appInfo && appInfo.data && appInfo.data.application.name !== "approval service") {
    //     newActions.map((d, index)=> {
    //       if(d.children){
    //         d.children.map((e,ind)=>{
    //           if(e.label == "Flow Approval") {
    //             delete sidebarMenu[index].children[ind]
    //           }
    //         })
    //       }
    //     })
    //   }
    // }
  } catch(e){}
  // Handle Permission Approval

  return (
    <>
      <PageWrapper
        sidebarCollapsible={true}
        sidebarDefaultCollapse={false}
        inverseTheme={true}
        style={{background: '#D14C21'}}
        sidebarHeader={(
          <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
            <ListItem style={{ marginTop: '-3px' }} secondaryText={`${t.translate('words.pilihPerusahaan')}`} rightAddon={<FontIcon onClick={()=>{
              window.location.href = `/product/index/companies`
            }} iconClassName="mdi mdi-logout"></FontIcon>}>
            </ListItem>
          </List>
        )}
        sidebarMenu={sidebarMenu}
      >
        <Router history={history}>
          <Switch>
            <Route 
              path = '/product/company/:companyId/email'
              render = {props => (
                <EmailForm {...props}/>
              )}
            />
            <Route 
              path = '/product/company/:companyId/log-email/:id/info'
              render = {props => (
                <LogEmailInfoTable {...props}/>
              )}
            />
            <Route 
              path = '/product/company/:companyId/log-email/:id/history'
              render = {props => (
                <LogEmailHistoryView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/log-email'
              render={props => (
                <LogEmailView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/log-export/:id'
              render={props => (
                <LogExportForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/log-export'
              render={props => (
                <LogExportView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/bp26/bp26-bulk'
              render={props => (
                <Bp26BulkForm {...props}/>
              )}
            />
            <Route 
              path = '/product/company/:companyId/bp26/:id/history'
              render = {props => (
                <Bp26History {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/bp26'
              render={props => (
                <Bp26View {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/bp/bp-bulk'
              render={props => (
                <BpBulkForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/bp/:id/history'
              render={props => (
                <BpHistory {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/bp'
              render={props => (
                <BpView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/doss2/:id/history'
              render={props => (
                <Doss2HistoryView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/doss2'
              render={props => (
                <Doss2View {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-bpe'
              render={props => (
                <DaftarBpeView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/essp/calc'
              render={props => (
                <DataEsspForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/essp'
              render={props => (
                <DataEsspView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/ebilling-widget'
              render={props => (
                <EbillingWidget {...props}/>
              )}
            />
            <Route 
              path = '/product/company/:companyId/daftar-spt/:id/history'
              render = {props => (
                <DaftarHistorySpt {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/upload-sertel'
              render={props => (
                <UploadSertelTable {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/newBulk'
              render={props => (
                <DaftarSptBulkForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/notification'
              render={props => (
                <Notifikasi {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/induk'
              render={props => (
                <DaftarCetakIndukForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/export'
              render={props => (
                <DaftarSptExportForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/:id'
              render={props => (
                <DaftarSptForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt'
              render={props => (
                <DaftarSptView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/export'
              render={props => (
                <EksporData {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/dashboard'
              render={props => (
                <DashboardSample {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/table'
              render={props => (
                <TableSample {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/form'
              render={props => {
                <FormSample {...props}/>
              }}
            />
            <Route 
              path='/product/company/:companyId/ssp/:id/history'
              render={props => (
                <SspHistory {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/ssp/:id'
              render={props => (
                <SspForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/ssp'
              render={props => (
                <SspView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/pbk/:id/history'
              render={props => (
                <PbkHistory {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/pbk/:id'
              render={props => (
                <PbkForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/pbk'
              render={props => (
                <PbkView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/lawan-transaksi/:id'
              render={props => (
                <LawanTransaksiForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/lawan-transaksi'
              render={props => (
                <LawanTransaksiView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/signer/:id'
              render={props => (
                <SignerForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/signer'
              render={props => (
                <SignerView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/dashboard'
              render={props => (
                <DashboardSample {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/table'
              render={props => (
                <TableSample {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/form'
              render={props => {
                <FormSample {...props}/>
              }}
            />
            <Route
              path='/product/company/:companyId/organization/new'
              render={props => (
                <OrganizationForm {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/organization'
              render={props => (
                <Organization {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/user/new'
              render={props => (
                <UserForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/user'
              render={props => (
                <User {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import/:id/import-detail-csv'
              render={props => (
                <ImportLogCsv {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import/:id/import-detail'
              render={props => (
                <ImportLog {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import'
              render={props => (
                <ImportData {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import-bp'
              render={props => (
                <ImportBp {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import-setor-sendiri'
              render={props => (
                <ImportSetorSendiri {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import-doss-dopp'
              render={props => (
                <ImportDossDopp {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import-ssp-pbk'
              render={props => (
                <ImportSspPbk {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/import-essp'
              render={props => (
                <ImportEssp {...props}/>
              )}
            />
            
            <Route 
              path='/product/company/:companyId/dashboard-spt'
              render={props => (
                <DashboardSpt {...props}/>
                // <></>
              )}
            />
            {/* <Route 
              path='/product/company/:companyId/organization'
              render={props => (
                <DashboardSertelView {...props}/>
              )}
            /> */}
            <Route 
              path='/product/company/:companyId/flow-approval/:id'
              render={props => (
                <ApprovalFlowForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/flow-approval'
              render={props => (
                <ApprovalFlowTable {...props}/>
              )}
            />
          </Switch>
        </Router>
      </PageWrapper>
    </>
  )
}

export default sso({
  basePath: '/product/company/:companyId',
  url:{
    me:'/api/sso/company/:companyId/me'
  }
})(Company)
