import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'
import axios from 'axios'

let service = new CrudService('/api/sso/local/schedule/mail');

service.getByCompany = async(companyId, username)=> {
  var companyId = btoa(companyId+"_secret!Email25b95b7d08-47fe-42a3-b427-f43b8f856a2d");
  return axios({
    method: http.methods.GET,
    url: `/api/sso/local/schedule/mail/company/${companyId}?createdBy=${username}`,
  })
}

service.putByCompany = async(companyId, data)=> {
  var companyId = btoa(companyId+"_secret!Email25b95b7d08-47fe-42a3-b427-f43b8f856a2d");
  return axios({
    method: http.methods.PUT,
    url: `/api/sso/local/schedule/mail/company/${companyId}`,
    data: data
  })
}

service.getDetails = async(settingId)=> {
  var settingId = btoa(settingId+"_secret!Email25b95b7d08-47fe-42a3-b427-f43b8f856a2d");
  return axios({
    method: http.methods.GET,
    url: `/api/sso/local/schedule/mail/setting/${settingId}`,
  })
}

service.removeItem = async(id)=> {
  return axios({
    method: http.methods.DELETE,
    url: `/api/sso/local/scheduleDate/${id}`,
  })
}

service.saveItem = async(data)=> {
  return axios({
    method: http.methods.POST,
    url: `/api/sso/local/scheduleDate`,
    data: data
  })
}

export default service;