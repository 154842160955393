import React from 'react'
import { TextArea, TextField, Password, FontIcon, AutoComplete } from 'react-md'
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { inputTypes } from '../../config/constant'
import './CustomInput.scss'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const CustomInput = ({
  id                  = 'mpk-custom-input',
  className           = '',
  inputType           = inputTypes.INPUT,
  helpText            = null,
  errorMessage        = null,
  containerStyle      = {},
  label, value, onChange, defaultValue,
  ...props
}) => {
  const render = () => {
    if(value == null) value = ""
    switch(inputType){
      case inputTypes.REACT_SELECT:
        try {
          var type = typeof value
          if(type != 'object'){
            var existing = props.options.filter((d)=> {
              return d.value == value
            })
            if(!existing[0]){
              value = null
              // value = {
              //   label: value,
              //   value: value
              // }
            } else {
              value = existing[0]
            }
          }
        } catch(e){
          value = null
        }
        // console.log(value)
        if(props.async){
          return <>
            <label className="react-select-label">{label}</label>
            <ReactSelectAsync 
              styles={{
                menu: (provided)=> ({
                  ...provided,
                  zIndex: 100,
                })
              }}
              className={className + " react-select-material rmd-text-field"}
              {...{id, label, value, onChange, defaultValue}}
              {...props}
              classNamePrefix='filter'
            />
          </>
        } else {
          return <>
            <label className="react-select-label">{label}</label>
            <ReactSelect 
              styles={{
                menu: (provided)=> ({
                  ...provided,
                  zIndex: 100,
                })
              }}
              className={className + " react-select-material rmd-text-field"}
              {...{id, label, value, onChange, defaultValue}}
              {...props}
              classNamePrefix='filter'
            />
          </>
        }
      case inputTypes.DATEPICKER: 
        try {
          if(value) value = new Date(value)
          if(value.toString() === 'Invalid Date'){
            value = null
          }
        } catch(e){
          
        }
        return <DatePicker 
            {...{id, label, value, onChange, defaultValue}}
            {...props}
            // customInput={<NumberFormat onValueChange={(e)=> { onChange({target: {value: e.value}}) }}  mask="_" format="##/##/####" customInput={TextField} onBlur={()=> {}} label={label} className={props.className} id={id} style={props.style} style={{ width: '100%' }} />}
            customInput={<TextField onBlur={()=> {}} label={label} className={props.className} id={id} style={props.style} style={{ width: '100%' }} />}
            style={{ width: '100%' }}
            //locale="id"
            dateFormat="dd/MM/yyyy"
            selected={value}
            onBlur={()=> {}}
            placeholderText="dd/MM/yyyy"
            onSelect={(e)=> {
              try {
                e = moment(e).format('YYYY-MM-DD')
                onChange({target: {value: e}})
              } catch(e){}
            }}
            onChange={(e)=> {
              try {
                e = moment(e).format('YYYY-MM-DD')
                onChange({target: {value: e}})
              } catch(e){
                console.log(e, "TELL ME")
              }
            }}
        />
      case inputTypes.AUTOCOMPLETE:
        return <AutoComplete
          {...{id, label, value, onChange, defaultValue}}
          {...props}
        />
      case inputTypes.TEXTAREA:
        return <TextArea
          on
          {...{id, label, value, onChange, defaultValue}}
          {...props}
        />
      case inputTypes.INPUT_MASK:
        return (
          <InputMask
            {...{id, label, value, onChange, defaultValue}}
            {...props}>
            {(inputProps) => <TextField {...inputProps} />}
          </InputMask>
        )
      case inputTypes.INPUT_MASK_NUMBER:
        return (
          <NumberFormat customInput={TextField} {...{id, label, value, defaultValue}} {...props} onBlur={()=>{}} format={props.mask} mask={props.maskChar} allowEmptyFormatting onValueChange={(e)=>{
            if(props.maskValue === 'format'){
              try {
                onChange({target: {value: e.formattedValue}})
              } catch(e){}
            } else if(props.maskValue === 'string'){
              try {
                onChange({target: {value: e.value}})
              } catch(e){}
            } else {
              try {
                onChange({target: {value: e.floatValue || 0}})
              } catch(e){}
            }
          }} />
        )
      case inputTypes.INPUT:
      default:
        if(props.type == 'password'){
          return <Password
            {...{id, label, value, onChange, defaultValue}}
            {...props}
          />
        } else {
          return <TextField
            {...{id, label, value, onChange, defaultValue}}
            {...props}
          />
        }
    }
  }

  return (
    <div
      className={`mpk-custom-input ${className}`}
      style={containerStyle}
    >
      {render()}
      { errorMessage ? (
        <div className="message error-text mpk-font weight-B mpk-flex align-center">
          <FontIcon
            iconClassName="mdi mdi-alert"
            style={{fontSize:12}}
            className="mpk-margin-S margin-right"
          />
          {errorMessage}
        </div>
      ) : (
        helpText ? (
          <div className="message help-text">{helpText}</div>
        ) : null
      )}
    </div>
  )
}

CustomInput.types = inputTypes

export default CustomInput
