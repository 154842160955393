import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Divider, Button, FontIcon } from 'react-md'
import Modal from '../../libs/react-mpk/components/Modal'
import LawanTrasaksiTable from './../LawanTransaksi/LawanTransaksi.table'
import ErrorService from './../../services/errorService'
import LawanTransaksiService from './../LawanTransaksi/LawanTransaksi.service'

const Bp1Form = ({ authStore, envStore, temporaryStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [customRules, setCustomRules] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [qq, setQQ] = useState(false)
  const [showBp23, setShowBp23] = useState(false)
  const [showBp23Nik, setShowBp23Nik] = useState(false)
  const [negara, setNegara] = useState([])
  const [province, setProvince] = useState([])
  const [city, setCity] = useState([])
  const [showcity, setShowCity] = useState(true)
  const [district, setDistrict] = useState([])
  const [showdistrict, setShowDistrict] = useState(true)
  const [subdistrict, setSubDistrict] = useState([])
  const [subshowdistrict, setShowSubDistrict] = useState(true)
  const [inputMasks, setInputMasks] = useState('##.###.###.#-###.###')
  const [isHidden, setIsHidden] = useState(false)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      var provinces = await getProvince()
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      setData({loading: false, content: {
        qq: false,
        identityAs: "0",
        ...bpform,
      }})
      // if(bpform.identityAs == '1'){
      //   setInputMasks('################')
      // } else {
      //   setInputMasks('##.###.###.#-###.###')
      // }
      // filterKota
      provinces.map((d)=> {
        if(bpform.province == d.name) getCity(null, d.code)
      })
      // filterDistrict
      try {
        let getCityz = await LawanTransaksiService.getCity(bpform.city)
        getDistrict(null, getCityz.data[0].code);
      } catch(e){}
      // filterSubDistrict
      try {
        let getDistrictz = await LawanTransaksiService.getDistrict(bpform.district)
        getSubDistrict(null, getDistrictz.data[0].code);
      } catch(e){}
      // setCustomRules({ identity: ['required', 'min:15'] })
      setShowBp23(true)
    }
    initData()
    if(match.params.id != 'new'){
      if(bpform.status == 'FINISH' || bpform.status == 'DELETED' || bpform.status == 'CANCELED'){
        setIsHidden(true)
      }else{
        setIsHidden(false)
      }
      if(bpform.identityAs == '1'){
        setInputMasks('################')
      } else {
        setInputMasks('##.###.###.#-###.###')
      }
    } else {
      try {
        if(bpform.identityAs == '1'){
          setInputMasks('################')
        } else {
          setInputMasks('##.###.###.#-###.###')
        }
      } catch(e){}
    }
  }, [])

  function _closeDialog() {
    setShowModal(false)
  }

  async function getNegara(query){
    try {
      var negara = await LawanTransaksiService.getNegara(query)
      setNegara(negara.data)
      return negara.data
    } catch(e){
      ErrorService(e)
    }
  }

  async function getProvince(query){
    try {
      var province = await LawanTransaksiService.getProvince(query)
      setProvince(province.data)
      return province.data
    } catch(e){
      ErrorService(e)
    }
  }

  async function getCity(query, provinceId){
    try {
      setShowCity(false)
      setTimeout(async ()=> {
        var city = await LawanTransaksiService.getCity(query, provinceId)
        setCity(city.data)
        setShowCity(true)
        return city.data
      }, 500)
    } catch(e){
      ErrorService(e)
    }
  }

  async function getDistrict(query, cityId){
    try {
      setShowDistrict(false)
      setTimeout(async ()=> {
        var district = await LawanTransaksiService.getDistrict(query, cityId)
        setDistrict(district.data)
        setShowDistrict(true)
        return district.data
      }, 500)
    } catch(e){
      ErrorService(e)
    }
  }

  async function getSubDistrict(query, districtId){
    try {
      setShowSubDistrict(false)
      setTimeout(async ()=> {
        var subDistrict = await LawanTransaksiService.getSubDistrict(query, districtId)
        setSubDistrict(subDistrict.data)
        setShowSubDistrict(true)
        return subDistrict.data
      }, 500)
    } catch(e){
      ErrorService(e)
    }
  }

  var onSubmit = async (data, callback) => {
    data.form1valid = true
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    bpform = Object.assign(bpform, data)
    localStorage.setItem('bpform', JSON.stringify(bpform))
    props.activePanel()
  }
  try {
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(bpform.status == 'CANCELED' || bpform.status == 'DELETED' || spt.status == 'SUBMIT' || spt.status == 'FINISH'){ 
      onSubmit = false
    }
  } catch(e){}

  var isEdit = false
  if(match.params && match.params.id != "new"){
    isEdit = true
  }

  return (
    <>
      <Modal
        disableFocusOnMount={true}
        id="lt-form"
        aria-label="lt-form"
        visible={showModal}
        onRequestClose={_closeDialog}
        style={{
          width: 1024, 
          height: 600
        }}
      >
        <LawanTrasaksiTable match={match} onSearch={true} customRules={customRules} inputMasks={inputMasks} setInputMasks={setInputMasks} setShowModal={setShowModal} setData={setData} data={data} />
      </Modal>
      <Button theme="primary" themeType="contained" style={{ margin: 15, width: '25%' }} onClick={()=> setShowModal(true)} hidden={isHidden}><FontIcon iconClassName="mdi mdi-magnify" />
        {t.translate('words.cariLT')}
      </Button>
      <FormWrapper
        submitLabel={t.translate('words.next')}
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}-1`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        onChange={(formData, key, value)=> {
          // PVC
          try {
            if(formData.identityAs == "1"){
              // if(!formData.id){
              //     if(formData.province == "-"){
              //       formData.province = null
              //     }
              // }
              // if(!formData.id){
              //   if(formData.city == "-"){
              //     formData.city = null
              //   }
              // }
              setCustomRules({ identity: ['required', 'min:16'], name : 'required' })
              setInputMasks('################')
              setShowBp23(true)
              setShowBp23Nik(true)
            }
            if(formData.identityAs == "0"){
              // if(!formData.id){
              //     if(formData.province == null){
              //       formData.province = "-"
              //     }
              // }
              // if(!formData.id){
              //   if(formData.city == null){
              //     formData.city = "-"
              //   }
              // }
              setCustomRules({ identity: ['required', 'min:15'] })
              setInputMasks('##.###.###.#-###.###')
              setShowBp23(true)
              setShowBp23Nik(false)
            }
          } catch(e){}
          // PVC
          if(key == "qq") setQQ(value)
          if(formData.qq == true){
            customRules.qqName = 'required'
          } else {
            delete customRules.qqName
          }
   
          if(key == 'identityAs' && value == '0'){
            setCustomRules({ identity: ['required', 'min:15'] })
            setInputMasks('##.###.###.#-###.###')
            setShowBp23(true)
            setShowBp23Nik(false)
          }
          if(key == 'identityAs' && value == '1'){
            setCustomRules({ identity: ['required', 'min:16'], name : 'required'})
            setInputMasks('################')
            setShowBp23(true)
            setShowBp23Nik(true)
          }
          if(key == 'identityAs' && value == '2'){
            setCustomRules({ identity: ['required'], country: 'required' })
            setShowBp23(false)
            setShowBp23Nik(false)
          }
          if(key == 'countryName'){
            negara.map((d)=> {
              if(d.name == value) formData.country = d.code
            })
          }
          if(key == 'province'){
            formData['city'] = null
            formData['district'] = null
            formData['subDistrict'] = null
            province.map((d)=> {
              if(d.name == value) getCity(null, d.code)
            })
          }
          if(key == 'city'){
            formData['district'] = null
            formData['subDistrict'] = null
            city.map((d)=> {
              if(d.name == value) getDistrict(null, d.code)
            })
          }
          if(key == 'district'){
            formData['subDistrict'] = null
            district.map((d)=> {
              if(d.name == value) getSubDistrict(null, d.code)
            })
          }
        }}
        customRules={customRules}
        definitions={[
          {
            inputType: inputTypes.RADIO,
            label: t.translate(`words.identityNpwp`),
            key: 'identityAs',
            name: 'identityAs',
            width: '50%',
            value: "0",
            style: {
              width: '15%'
            }
          },
          {
            inputType: inputTypes.RADIO,
            label: t.translate(`words.identityNik`),
            key: 'identityAs',
            name: 'identityAs',
            width: '50%',
            value: "1",
            style: {
              width: '85%'
            }
          },
          // {
          //   inputType: inputTypes.RADIO,
          //   label: t.translate(`words.identityTin`),
          //   key: 'identityAs',
          //   name: 'identityAs',
          //   width: '50%',
          //   value: "2",
          // },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: t.translate(`words.identity`),
            key: 'identity',
            type: 'text',
            mask: inputMasks,
            maskChar: '_',
            maskValue: 'string',
            width: '25%'
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.name`),
            key: 'name',
            type: 'text',
            // validation: 'required',
            width: '75%',
            show: (showBp23 && showBp23Nik),
          },
          {
            render : (
              <div style={{ width : "75%"}} />
            )
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.qq`),
            key: 'qq',
            type: 'text',
            width: '25%',
            show: (showBp23 && !showBp23Nik),
            style: {
              width: '25%'
            }
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.qqName`),
            key: 'qqName',
            type: 'text',
            disabled: !qq,
            width: '75%',
            show: (showBp23 && !showBp23Nik)
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.email`),
            key: 'email',
            type: 'text',
            //width: '25%',
          },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.address`),
          //   key: 'address',
          //   type: 'text',
          //   validation: 'required',
          //   show : (isEdit)
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.phone`),
          //   key: 'phone',
          //   type: 'text',
          //   width: '50%',
          //   validation: 'required', 
          //   show : (isEdit)
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.postalCode`),
          //   key: 'postalCode',
          //   type: 'text',
          //   // show: showBp23,
          //   validation: 'required',
          //   width: '50%',
          //   show : (isEdit)
          // },
          // {
          //   inputType: inputTypes.AUTOCOMPLETE,
          //   label: t.translate(`words.country`),
          //   key: 'countryName',
          //   colSpan: 3,
          //   data: negara,
          //   labelKey: 'name', 
          //   valueKey: 'name',
          //   show: !showBp23
          // },
          // {
          //   inputType: inputTypes.AUTOCOMPLETE,
          //   label: t.translate(`words.province`),
          //   key: 'province',
          //   colSpan: 3,
          //   data: province,
          //   labelKey: 'name', 
          //   valueKey: 'name',
          //   width: '50%',
          //   // show: showBp23,
          //   show : (isEdit)
          // },
          // {
          //   inputType: inputTypes.AUTOCOMPLETE,
          //   label: t.translate(`words.city`),
          //   key: 'city',
          //   colSpan: 3,
          //   data: city,
          //   labelKey: 'name', 
          //   valueKey: 'name',
          //   width: '50%',
          //   // show: showBp23 && showcity,
          //   show : (isEdit)
          // },
          // {
          //   inputType: inputTypes.AUTOCOMPLETE,
          //   label: t.translate(`words.district`),
          //   key: 'district',
          //   colSpan: 3,
          //   data: district,
          //   labelKey: 'name', 
          //   valueKey: 'name',
          //   width: '50%',
          //   // show: showBp23 && showdistrict,
          //   show : (isEdit)
          // },
          // {
          //   inputType: inputTypes.AUTOCOMPLETE,
          //   label: t.translate(`words.subDistrict`),
          //   key: 'subDistrict',
          //   colSpan: 3,
          //   data: subdistrict,
          //   labelKey: 'name', 
          //   valueKey: 'name',
          //   width: '50%',
          //   // show: showBp23 && subshowdistrict,
          //   show : (isEdit)
          // },
        ]}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(Bp1Form))
