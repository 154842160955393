import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { Dialog, List, ListItem, DialogContent, DialogFooter  } from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import service from './OpOrganization.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import errorServiceGet from './../../services/errorServiceGet'

const OpOrganization = ({ 
  match, 
  navigationStore, 
  modalStore, 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [organization, setOrganization] = useState([])
  const [kpp, setKpp] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [isShowActive, setIsShowActive] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [isShowKpp, setIsShowKpp] = useState(false)
  const [isShowOrg, setIsShowOrg] = useState(true)
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  
  const basePath = 'organization-op'
  const baseId = 'mod-organization-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
          setIsShow(true)
        } catch(e){
          ErrorService(e)
        }
      } else {
        const res = await service.getOne(match.params.id)
        res.data.companyNama = res.data.company.nama
        res.data.kpp = res.data.kpp.id
        console.log(res.data.kpp, 'udah')
        setData({loading: false, content: res.data})
        setIsShow(false)
        setIsShowActive(true)
        setIsShowKpp(true)
      }
      let user = JSON.parse(localStorage.getItem('user'))

      if(user.authorities[0].name == "ROLE_EPPT_OWNER" || window.location.href.indexOf('open') != -1){
        setIsShowOrg(false)
      }
      getCompany()
      getKpp()
    }
    initData()
  }, [])

  const getCompany = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['nama.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getCompany(params)
      res.data.map((d)=> {
        d.label = d.nama
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  const getKpp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'code,ASC'
      }
      if(isNaN(values)){
        params['nama.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getKpp(params)
      res.data.map((d)=> {
        d.label = d.name
        d.value = d.id
      })
      setKpp(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  const updateKey = async (item) => {
    setDialogItem(item)
    setDialog(true)
  }

  var actions = [
    new TableWrapper.action(`${t.translate('words.updateKey')}`, 'mdi mdi-key', (item) => updateKey(item), true),
  ]

  var edit = false
  if(match.params.id == 'new') {
    actions = []
  } else {
    edit = true
  }

  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <>
    <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
    </LoadingOverlay>
    <Dialog 
      disableFocusOnMount={true}
      initialFocus="updateKey-dialog"
      id="updateKey-dialog"
      onFocus={()=>{}}
      visible={dialog}
      onRequestClose={()=> {
        setDialog(false)
      }}
      style={{
        width: 1024,
        margin: '0px !important'
      }}
      width={1024}
      aria-labelledby="updateKey-dialog"
    >
      <DialogContent>
        <FormWrapper 
          style={{
            margin: '0px !important',
            width: '100%',
            maxWidth: '100%'
          }}
          defaultData={{
            npwp: dialogItem.fileName
          }}
          hintMessage={t.translate(`words.updateKey`)}
          showCommandbar={false}
          baseId = "mod-updateKey-form"
          title={t.translate(`words.updateKey`)}
          definitions={[
            {
              inputType : inputTypes.INPUT,
              label : t.translate('words.activationKey'),
              key : 'key',
              type : 'text',
              validation: 'required'
            },
          ]}
          onSubmit={async (values, callback)=> {
            var proses = t.translate('words.updateKey')
            setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
            var user = JSON.parse(localStorage.getItem('user'))
            try {
              var formData = Object.assign({}, values)
              formData.npwp = `${data.content.npwp}`
              await service.updateKey(formData)
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } catch(e){
              var errors = []
              errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            setDialog(false)
            setPloading({ loading: false, message: `` })
          }}
        />
      </DialogContent>
    </Dialog>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style ={{
        maxWidth : '80%'
      }}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      actions = {actions}
      definitions={[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'company',
          labelKey: 'label', 
          valueKey: 'label',
          data: organization,
          options : organization,
          show : (isShowOrg && !edit),
          async : true,
          defaultOptions : organization,
          loadOptions: async (inputValues)=> {
            var filter = await getCompany(inputValues);
            return filter
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.organization'),
          key: 'companyNama',
          type: 'text',
          show: edit,
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate('words.npwp'),
          key: 'npwp',
          type: 'text',
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          width : '50%',
          disabled: edit,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'name',
          type: 'text',
          width : '50%',
          validation : 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.aliasName'),
          key: 'aliasName',
          type: 'text',
          width : '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.city'),
          key: 'city',
          type: 'text',
          validation : 'required',
          width : '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.postalCode'),
          key: 'postalCode',
          type: 'text',
          width : '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'email',
          type: 'email',
          width : '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.phone'),
          key: 'phone',
          type: 'text',
          width : '50%',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.pemungut`),
          key: 'isPemungut',
          // value : data.content.isPemungut,
          width: '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.activationCode'),
          key: 'activationKey',
          type: 'text',
          validation : 'required',
          show : isShow
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.kpp`),
          key: 'kpp',
          labelKey: 'label', 
          valueKey: 'label',
          data: kpp,
          show : isShowKpp,
          options : kpp,
          width: '50%',
          async : true,
          defaultOptions : kpp,
          loadOptions: async (inputValues)=> {
            var filter = kpp.filter((org)=> {
              return org.label.indexOf(inputValues) != -1
            })
            return filter
          }
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.active`),
          key: 'active',
          // value : data.content.isPemungut,
          width: '50%',
          show : isShowActive
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.address'),
          key: 'address',
          type: 'text',
          validation : 'required'
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var user = JSON.parse(localStorage.getItem('user'))
          var formData = Object.assign({}, data)

          var company = JSON.parse(localStorage.getItem('company'))
          formData.company = company

          if(!formData.kpp){
            formData.kpp = {id : 1}
          }else{
            formData.kpp = {id : formData.kpp}
          }

          if(formData.active == null){
            formData.active = false
          }
          if(formData.isPemungut == null){
            formData.isPemungut = false
          }

          formData.npwp = `${formData.npwp}`

          delete formData.kpp.value
          if(match.params.id == 'new') {
            // if(window.location.href.indexOf('open') != -1 || user.authorities[0].name == "ROLE_EPPT_OWNER"){
            //     var companyId = user.company.id
            //     res = await service.createdById(formData, companyId)
            // } else {
              res = await service.createdOne(formData)
            // }
          } else {
            res = await service.editById(formData.id, formData)
          }

          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)  
             
        } catch(e){
          console.log(formData)
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore', 'modalStore')(observer(OpOrganization))
