import React, { useEffect, useState } from 'react'

import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './ApprovalFlow.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import { DiagramEngine, DiagramModel, DefaultNodeModel, DiagramWidget, DefaultPortModel, DefaultLinkModel, NodeModel } from 'storm-react-diagrams';
import {
  Card,
  CardContent,
  CardTitle,
  CardSubtitle,
  CardHeader,
  CardActions
} from '@react-md/card';
import { FontIcon } from 'react-md';
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import DataForm from '../../libs/react-mpk/components/DataForm'

const ApprovalFlow = ({
  match,
  navigationStore
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {
    useDefault: false
  } })
  const [customRules, setCustomRules] = useState({})
  const [company, setCompany] = useState([])

  const basePath = 'flow-approval'
  const baseId = 'mod-flow-approval-form'

  var getCompany = async () => {
    // var company = await service.getCompany()
    // company.data.map((d) => {
    //   d.label = d.nama
    //   d.value = d
    // })
    // setCompany(company.data)
    setCompany([])
  }

  var getUsers = async () => {
    // var users = await service.getUsers()
    // users.data.map((d) => {
    //   d.label = d.login
    //   d.value = d
    // })
    // setUsers(users.data)
    setUsers([])
  }

  // Engine Workflow
  var [nodeDialog, setNodeDialog] = useState(false);
  var [isEditDialog, setIsEditDialog] = useState(false);
  var [selectedItem, setSelectedItem] = useState({})
  var [editType, setEditType] = useState('State')
  var [users, setUsers] = useState([])
  var [engine, setEngine] = useState(null)
  var [model, setModel] = useState(null)
  

  useEffect(async ()=> {
    var engine;
    var model;
    try {
      engine = DiagramEngine;
      model = DiagramModel;

      engine = new DiagramEngine();
      engine.installDefaultFactories();
      model = new DiagramModel()

      var startNode = new DefaultNodeModel("Start", "#BDBDBD")
      var startPort = startNode.addOutPort("Out");
      startNode.extras = {
        id: startNode.id,
        name: 'Start',
        stateType: 'Start',
        status: 'WAITING'
      }
      startNode.setPosition(100, 100);

      var endApprovedNode = new DefaultNodeModel("End Approved", "#66BB6A");
      let endApprovedPort = endApprovedNode.addInPort("In");
      endApprovedNode.extras = {
        id: endApprovedNode.id,
        name: 'End Approved',
        stateType: 'End',
        status: 'FINISH'
      }
      endApprovedNode.setPosition(300, 100);

      var endRejectedNode = new DefaultNodeModel("End Rejected", "#EF5350");
      let endRejectedPort = endRejectedNode.addInPort("In");
      endRejectedNode.extras = {
        id: endRejectedNode.id,
        name: 'End Rejected',
        stateType: 'End',
        status: 'PROCESS'
      }
      endRejectedNode.setPosition(300, 200);

      var link1 = startPort.link(endRejectedPort);
      link1.addLabel("Reject");
      link1.extras.name = "Reject";
      link1.id = link1.id;
      link1.extras.fromState = {
        id: startNode.id
      }
      link1.extras.toState = {
        id: endRejectedNode.id
      }
      var link2 = startPort.link(endApprovedPort);
      link2.addLabel("Approve")
      link2.extras.name = "Approve";
      link2.extras.id = link2.id;
      link2.extras.fromState = {
        id: startNode.id
      }
      link2.extras.toState = {
        id: endApprovedNode.id
      }

      if (match.params.id == 'new') {
        engine.setDiagramModel(model);
        setModel(model)
        setEngine(engine)
        setData({ loading: false, content: {
          useDefault: false
        } })
      } else {
        engine.setDiagramModel(model);

        setModel(model)
        setEngine(engine)
        var res = await service.getFlowById(match.params.id)
        // res.data.company.label = res.data.company.nama
        // res.data.company.value = res.data.company
        model = new DiagramModel();
        var json = JSON.parse(res.data.jsonModel.replaceAll("\\", ""));
        model.deSerializeDiagram(json, engine);
        // console.log(json)
        json.links.forEach((d)=> {
          if(model.links[d.id].labels[0]) {
            model.links[d.id].labels[0].label = d.extras.name
          } else {
  
          }
        })
        engine.setDiagramModel(model);
        setModel(model)
        setEngine(engine)
        //
        setData({ loading: false, content: res.data })
      }
    } catch (e) {
      console.log(e)
    }
  }, [])
  // Engine Workflow

  var statusOptions = [
    {
      label: 'WAITING',
      value: 'WAITING'
    },
    {
      label: 'PROCESS',
      value: 'PROCESS'
    },
    {
      label: 'FINISH',
      value: 'FINISH'
    }
  ]

  return (
    <>
    <Dialog 
      visible={nodeDialog}
      disableFocusOnMount={()=> {}}
      onRequestClose={()=> {
        setNodeDialog(false)
      }}
      style={{
        width: 800, 
        height: 600
      }}
    >
      <DialogContent>
        <DataForm
          baseId={`${baseId}-state-manager`}
          defaultData={{}}
          hintShowIcon={true}
          hintMessage={t.translate('words.flowApprovalForm')}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name',
              type: 'text',
              width: '50%',
              validation: 'required'
            },
            {
              inputType: inputTypes.SELECT,
              label: t.translate(`words.tipeState`),
              key: 'stateType',
              type: 'text',
              width: '50%',
              validation: 'required',
              options: [
                { label: 'Start',  value: 'Start' },
                { label: 'Default',  value: 'Default' },
                { label: 'End',  value: 'End' },
              ]
            },
            {
              inputType: inputTypes.SELECT,
              label: t.translate(`words.status`),
              key: 'status',
              type: 'text',
              width: '50%',
              validation: 'required',
              options: statusOptions
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.description`),
              key: 'description',
              type: 'text',
              width: '50%',
              validation: 'required'
            },
            // {
            //   inputType: inputTypes.REACT_SELECT,
            //   label: t.translate(`words.assignToUser`),
            //   key: 'User',
            //   validation: 'required',
            //   labelKey: 'label',
            //   valueKey: 'label',
            //   data: users,
            //   options: users,
            //   async: true,
            //   defaultOptions: users,
            //   isMulti: true,
            //   loadOptions: async (inputValues)=> {
            //     var options = await service.getUsers(null, inputValues)
            //     if(options.data && options.data.length > 0){
            //       options.data.map((d) => {
            //         d.label = d.login
            //         d.value = d
            //       })
            //       return options.data
            //     } else {
            //       return []
            //     }
            //   }
            // },
            {
              inputType: inputTypes.SELECT,
              label: t.translate(`words.tipeState`),
              key: 'access',
              type: 'text',
              width: '100%',
              validation: 'required',
              options: [
                { label: 'Manage',  value: 'manage' },
                { label: 'Approve',  value: 'approve' },
                { label: 'Release',  value: 'release' },
                { label: 'Submit',  value: 'submit' },
              ]
            },
          ]}
          onSubmit={(val, callback)=> {
            if(isEditDialog && selectedItem.id){

            } else {
              var c = "ASDASDSADS"
              var color = "#BDBDBD";

              if (val.status == 'FINISH') color = "#66BB6A"
              if (val.status == 'PROCESS') color = "#EF5350"
        
              var newNode = new DefaultNodeModel(val.name, color);
              if (val.stateType != 'End') newNode.addOutPort("Out");
              if (val.stateType != 'Start') newNode.addInPort("In");
              newNode.setPosition(0, 0);

              var users = []
        
              if(val.User && val.User.length > 0){
                val.User.map((user)=> {
                  delete user.value
                  delete user.authorities
                  users.push({
                    user: user
                  })
                })
              }
              val.flowStateUsers = users

              newNode.extras = _.cloneDeep(val);
              newNode.extras.id = newNode.id;
        
              model.addNode(newNode);
              engine.setDiagramModel(model);
              setEngine(engine)
            }
            setNodeDialog(false)
            callback(true, "", "", "")
          }}
        >
          
        </DataForm>
      </DialogContent>
    </Dialog>
    <FormWrapper
      loading={data.loading}
      style={{
        maxWidth: '100%'
      }}
      backTo={`/product/company/${match.params.companyId}/${basePath}`}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value) => { }}
      customRules={customRules}
      defaultData={data.content}
      definitions={[
        // {
        //   inputType: inputTypes.REACT_SELECT,
        //   label: t.translate(`words.company`),
        //   key: 'company',
        //   validation: 'required',
        //   labelKey: 'label',
        //   valueKey: 'label',
        //   data: company,
        //   options: company,
        //   async: true,
        //   defaultOptions: company,
        //   loadOptions: async (inputValues)=> {
        //     var options = await service.getCompany(null, inputValues)
        //     if(options.data && options.data.length > 0){
        //       options.data.map((d) => {
        //         d.label = d.nama
        //         d.value = d
        //       })
        //       return options.data
        //     } else {
        //       return []
        //     }
        //   }
        // },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'name',
          type: 'text',
          width: '50%',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.description'),
          key: 'description',
          type: 'text',
          validation: 'required',
          width: '50%',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate('words.useDefault'),
          key: 'useDefault',
          type: 'text',
          width: '50%',
        },
        {
          render: (
            <Card className="srd-diagram">
            <CardActions style={{ justifyContent: 'start' }}>
              <Button><FontIcon iconClassName="mdi mdi-plus" onClick={async ()=> { 
                setEditType('State')
                setNodeDialog(true)
                setIsEditDialog(false)
               }}></FontIcon></Button>
              <Button><FontIcon iconClassName="mdi mdi-pencil"></FontIcon></Button>
              <Button><FontIcon iconClassName="mdi mdi-selection" onClick={()=> { engine.zoomToFit() }}></FontIcon></Button>
            </CardActions>
            <CardContent>
              <div className="mpk-bluepring-bg">
                <DiagramWidget diagramEngine={engine} />
              </div>
            </CardContent>
            </Card>
          )
        }
      ]}
      onSubmit={async (data, callback) => {
        try {
          if(data && data.company) delete data.company.value
          data.formType = 'Global'
          var res = {}
          var diagramJson = model.serializeDiagram();
          data = {
            // formType: 'Global',
            // mainFlow: data,
            ...data,
            states: [],
            actions: []
          }
          // data.mainFlow.jsonModel = JSON.stringify(diagramJson)
          data.jsonModel = JSON.stringify(diagramJson)
          console.log(diagramJson)
          diagramJson.nodes.forEach((d:DefaultNodeModel, i)=> {
            data.states.push(d.extras)
          })
          diagramJson.links.forEach((d:DefaultLinkModel, i) => {
            if(!d.extras.fromState) {
              d.extras.fromState = {
                id: d.source
              }
              d.extras.toState = {
                id: d.target
              }
              // try {
              //   var getNameId = d.source
              //   console.log(getName)
              //   var getName = diagramJson.nodes.filter((c)=> {
              //     return c.id = getNameId
              //   })
              //   d.extras.fromState.name = getName[0].name
              // } catch(e){
              //   console.log(e, 'GET NAME RRROR')
              // }
              // try {
              //   var getNameId = d.source
              //   console.log(getName)
              //   var getName = diagramJson.nodes.filter((c)=> {
              //     return c.id = getNameId
              //   })
              //   d.extras.toState.name = getName[0].name
              // } catch(e){}
            }
            data.actions.push(d.extras)
          })
          // console.log(data, "GETT NAME FOR EXTRA")
          // GET NAME
          try {
           
          } catch(e){}
          // GET NAME
          var formData = Object.assign({}, data)
          if(match.params.id == "new"){
            res = await service.post(formData)
          } else {
            res = await service.put(formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`/product/company/${match.params.companyId}/${basePath}`)
        } catch (e) {
          ErrorService(e)
          callback("", false, false, false)
          return
        }
      }}
    />
    </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(ApprovalFlow))


// import React, { useEffect, useState } from 'react'

// import { FormWrapper } from '../../libs/react-mpk/wrapper'
// import t from 'counterpart'
// import { inject, observer } from 'mobx-react'
// import service from './ApprovalFlow.service'
// import _ from 'lodash'
// import ErrorService from './../../services/errorService'
// import { DiagramEngine, DiagramModel, DefaultNodeModel, DiagramWidget, DefaultPortModel, DefaultLinkModel, NodeModel } from 'storm-react-diagrams';
// import {
//   Card,
//   CardContent,
//   CardTitle,
//   CardSubtitle,
//   CardHeader,
//   CardActions
// } from '@react-md/card';
// import { FontIcon } from 'react-md';
// import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
// import DataForm from '../../libs/react-mpk/components/DataForm'

// const ApprovalFlow = ({
//   match,
//   navigationStore
// }) => {
//   const { inputTypes, definition } = FormWrapper
//   const [data, setData] = useState({ loading: true, content: {} })
//   const [customRules, setCustomRules] = useState({})
//   const [company, setCompany] = useState([])

//   const basePath = 'flow-approval'
//   const baseId = 'mod-flow-approval-form'

//   useEffect( async () => {
//     var engine;
//     var model;
//     try {
//       engine = DiagramEngine;
//       model = DiagramModel;

//       engine = new DiagramEngine();
//       engine.installDefaultFactories();
//       model = new DiagramModel()

//       var startNode = new DefaultNodeModel("Start", "#BDBDBD")
//       var startPort = startNode.addOutPort("Out");
//       startNode.extras = {
//         id: startNode.id,
//         name: 'Start',
//         stateType: 'Start',
//         status: 'WAITING'
//       }
//       startNode.setPosition(100, 100);

//       var endApprovedNode = new DefaultNodeModel("End Approved", "#66BB6A");
//       let endApprovedPort = endApprovedNode.addInPort("In");
//       endApprovedNode.extras = {
//         id: endApprovedNode.id,
//         name: 'End Approved',
//         stateType: 'End',
//         status: 'FINISH'
//       }
//       endApprovedNode.setPosition(300, 100);

//       var endRejectedNode = new DefaultNodeModel("End Rejected", "#EF5350");
//       let endRejectedPort = endRejectedNode.addInPort("In");
//       endRejectedNode.extras = {
//         id: endRejectedNode.id,
//         name: 'End Rejected',
//         stateType: 'End',
//         status: 'PROCESS'
//       }
//       endRejectedNode.setPosition(300, 200);

//       var link1 = startPort.link(endRejectedPort);
//       link1.addLabel("Reject");
//       link1.extras.name = "Reject";
//       link1.id = link1.id;
//       link1.extras.fromState = {
//         id: startNode.id
//       }
//       link1.extras.toState = {
//         id: endRejectedNode.id
//       }
//       var link2 = startPort.link(endApprovedPort);
//       link2.addLabel("Approve")
//       link2.extras.name = "Approve";
//       link2.extras.id = link2.id;
//       link2.extras.fromState = {
//         id: startNode.id
//       }
//       link2.extras.toState = {
//         id: endApprovedNode.id
//       }

//       if (match.params.id == 'new') {
//         engine.setDiagramModel(model);
//         setModel(model)
//         setEngine(engine)
//         setData({ loading: false })
//       } else {
//         engine.setDiagramModel(model);
//         setModel(model)
//         setEngine(engine)
//         var res = await service.getFlowById(match.params.id)
//         //
//         // res.data.company.label = res.data.name
//         res.data.company = res.data.companyId
//         model = new DiagramModel();
//         var json = JSON.parse(res.data.jsonModel);
//         model.deSerializeDiagram(json, engine);
//         // console.log(json)
//         json.links.forEach((d)=> {
//           if(model.links[d.id].labels[0]) {
//             model.links[d.id].labels[0].label = d.extras.name
//           } else {
  
//           }
//         })
//         engine.setDiagramModel(model);
//         setModel(model)
//         setEngine(engine)
//         //
//         setData({ loading: false, content: res.data })
//       }
//     } catch (e) {
//       console.log(e)
//     }
//     getCompany()
//     getUsers()
//   }, [])

//   var getCompany = async (values) => {
//     var params = {
//       page: 0,
//       size: 200
//     }
//     if(isNaN(values)){
//       params['field'] = 'name'
//       params['query'] = values
//     } else {
//       params['field'] = 'npwp'
//       params['query'] = values
//     }
//     let organization = await service.getCompany(params)
//     var getAll = organization.data
//     var organizationsFilter = []
//     try {
//       getAll.map((og)=> {
//         og.label = og.npwp + " - " + og.name
//         if(og.certExists) organizationsFilter.push({
//           value: og,
//           label: og.label,
//           companyId : og.companyId
//         })
//       })
//     } catch(e){
//       organization.data.map((d)=> {
//         d.label = d.npwp + " - " + d.name
//         organizationsFilter.push({
//           value: d,
//           label: d.label,
//           companyId : d.companyId
//         })
//       })
//     }
//     setCompany(organizationsFilter)
//     return organizationsFilter
//   }

//   var getUsers = async () => {
//     var params = {
//       page: 0,
//       size: 200,
//       field : 'name'
//     }
//     var users = await service.getUsers(params)
//     users.data.map((d) => {
//       d.label = d.login
//       d.value = d
//     })
//     setUsers(users.data)
//   }

//   // Engine Workflow
//   var [nodeDialog, setNodeDialog] = useState(false);
//   var [isEditDialog, setIsEditDialog] = useState(false);
//   var [selectedItem, setSelectedItem] = useState({})
//   var [editType, setEditType] = useState('State')
//   var [users, setUsers] = useState([])
//   var [engine, setEngine] = useState(null)
//   var [model, setModel] = useState(null)
  

//   useEffect(async ()=> {
//     var engine;
//     var model;
//     try {
//       engine = DiagramEngine;
//       model = DiagramModel;

//       engine = new DiagramEngine();
//       engine.installDefaultFactories();
//       model = new DiagramModel()

//       var startNode = new DefaultNodeModel("Start", "#BDBDBD")
//       var startPort = startNode.addOutPort("Out");
//       startNode.extras = {
//         id: startNode.id,
//         name: 'Start',
//         stateType: 'Start',
//         status: 'WAITING'
//       }
//       startNode.setPosition(100, 100);

//       var endApprovedNode = new DefaultNodeModel("End Approved", "#66BB6A");
//       let endApprovedPort = endApprovedNode.addInPort("In");
//       endApprovedNode.extras = {
//         id: endApprovedNode.id,
//         name: 'End Approved',
//         stateType: 'End',
//         status: 'FINISH'
//       }
//       endApprovedNode.setPosition(300, 100);

//       var endRejectedNode = new DefaultNodeModel("End Rejected", "#EF5350");
//       let endRejectedPort = endRejectedNode.addInPort("In");
//       endRejectedNode.extras = {
//         id: endRejectedNode.id,
//         name: 'End Rejected',
//         stateType: 'End',
//         status: 'PROCESS'
//       }
//       endRejectedNode.setPosition(300, 200);

//       var link1 = startPort.link(endRejectedPort);
//       link1.addLabel("Reject");
//       link1.extras.name = "Reject";
//       link1.id = link1.id;
//       link1.extras.fromState = {
//         id: startNode.id
//       }
//       link1.extras.toState = {
//         id: endRejectedNode.id
//       }
//       var link2 = startPort.link(endApprovedPort);
//       link2.addLabel("Approve")
//       link2.extras.name = "Approve";
//       link2.extras.id = link2.id;
//       link2.extras.fromState = {
//         id: startNode.id
//       }
//       link2.extras.toState = {
//         id: endApprovedNode.id
//       }

//       if (match.params.id == 'new') {
//         engine.setDiagramModel(model);
//         setModel(model)
//         setEngine(engine)
//       } else {
//         engine.setDiagramModel(model);
//         setModel(model)
//         setEngine(engine)
//         var res = await service.getFlowById(match.params.id)
//         //
//         // res.data.company = res.data.name
//         res.data.company = res.data.companyId
//         model = new DiagramModel();
//         var json = JSON.parse(res.data.jsonModel);
//         model.deSerializeDiagram(json, engine);
//         // console.log(json)
//         json.links.forEach((d)=> {
//           if(model.links[d.id].labels[0]) {
//             model.links[d.id].labels[0].label = d.extras.name
//           } else {
  
//           }
//         })
//         engine.setDiagramModel(model);
//         setModel(model)
//         setEngine(engine)
//         //
//         setData({ loading: false, content: res.data })
//       }
//     } catch (e) {
//       console.log(e)
//     }
//   }, [])
//   // Engine Workflow

//   var getUsers = async () => {
//     var users = await service.getUsers()
//     users.data.map((d) => {
//       d.label = d.detail.username
//       d.value = d
//     })
//     setUsers(users.data)
//   }

//   var statusOptions = [
//     {
//       label: 'WAITING',
//       value: 'WAITING'
//     },
//     {
//       label: 'PROCESS',
//       value: 'PROCESS'
//     },
//     {
//       label: 'FINISH',
//       value: 'FINISH'
//     }
//   ]

//   return (
//     <>
//     <Dialog 
//       visible={nodeDialog}
//       disableFocusOnMount={()=> {}}
//       onRequestClose={()=> {
//         setNodeDialog(false)
//       }}
//       style={{
//         width: 800, 
//         height: 600
//       }}
//     >
//       <DialogContent>
//         <DataForm
//           baseId={`${baseId}-state-manager`}
//           defaultData={{}}
//           hintShowIcon={true}
//           hintMessage={t.translate('words.flowApprovalForm')}
//           definitions={[
//             {
//               inputType: inputTypes.INPUT,
//               label: t.translate(`words.name`),
//               key: 'name',
//               type: 'text',
//               width: '50%',
//               validation: 'required'
//             },
//             {
//               inputType: inputTypes.SELECT,
//               label: t.translate(`words.tipeState`),
//               key: 'stateType',
//               type: 'text',
//               width: '50%',
//               validation: 'required',
//               options: [
//                 { label: 'Start',  value: 'Start' },
//                 { label: 'Default',  value: 'Default' },
//                 { label: 'End',  value: 'End' },
//               ]
//             },
//             {
//               inputType: inputTypes.SELECT,
//               label: t.translate(`words.status`),
//               key: 'status',
//               type: 'text',
//               width: '50%',
//               validation: 'required',
//               options: statusOptions
//             },
//             {
//               inputType: inputTypes.INPUT,
//               label: t.translate(`words.description`),
//               key: 'description',
//               type: 'text',
//               width: '50%',
//               validation: 'required'
//             },
//             {
//               inputType: inputTypes.REACT_SELECT,
//               label: t.translate(`words.assignToUser`),
//               key: 'User',
//               validation: 'required',
//               labelKey: 'label',
//               valueKey: 'label',
//               data: users,
//               options: users,
//               async: true,
//               defaultOptions: users,
//               isMulti: true,
//               loadOptions: async (inputValues)=> {
//                 var options = await service.getUsers(null, inputValues)
//                 if(options.data && options.data.length > 0){
//                   options.data.map((d) => {
//                     d.label = d.detail.username
//                     d.value = d
//                   })
//                   return options.data
//                 } else {
//                   return []
//                 }
//               }
//             },
//             {
//               inputType: inputTypes.SELECT,
//               label: t.translate(`words.tipeState`),
//               key: 'access',
//               type: 'text',
//               width: '100%',
//               validation: 'required',
//               options: [
//                 { label: 'Manage',  value: 'manage' },
//                 { label: 'Approve',  value: 'approve' },
//                 { label: 'Release',  value: 'release' },
//                 { label: 'Submit',  value: 'submit' },
//               ]
//             },
//           ]}
//           onSubmit={(val, callback)=> {
//             if(isEditDialog && selectedItem.id){

//             } else {
//               var c = "ASDASDSADS"
//               var color = "#BDBDBD";

//               if (val.status == 'FINISH') color = "#66BB6A"
//               if (val.status == 'PROCESS') color = "#EF5350"
        
//               var newNode = new DefaultNodeModel(val.name, color);
//               if (val.stateType != 'End') newNode.addOutPort("Out");
//               if (val.stateType != 'Start') newNode.addInPort("In");
//               newNode.setPosition(0, 0);

//               var users = []
        
//               if(val.User && val.User.length > 0){
//                 val.User.map((user)=> {
//                   delete user.value
//                   delete user.authorities
//                   users.push({
//                     user: user
//                   })
//                 })
//               }
//               val.flowStateUsers = users

//               newNode.extras = _.cloneDeep(val);
//               newNode.extras.id = newNode.id;
        
//               model.addNode(newNode);
//               engine.setDiagramModel(model);
//               setEngine(engine)
//             }
//             setNodeDialog(false)
//             callback(true, "", "", "")
//           }}
//         >
          
//         </DataForm>
//       </DialogContent>
//     </Dialog>
//     <FormWrapper
//       loading={data.loading}
//       style={{
//         maxWidth: '100%'
//       }}
//       backTo={`/product/company/${match.params.companyId}/${basePath}`}
//       baseId={`mod-form-${basePath}`}
//       title={t.translate(`modules.${basePath}.formTitle`)}
//       onChange={(formData, key, value) => { }}
//       customRules={customRules}
//       defaultData={data.content}
//       definitions={[
//         {
//           inputType: inputTypes.REACT_SELECT,
//           label: t.translate(`words.company`),
//           key: 'company',
//           validation: 'required',
//           labelKey: 'label',
//           valueKey: 'companyId',
//           data: company,
//           options: company,
//           async: true,
//           defaultOptions: company,
//           loadOptions: async (inputValues) => {
//             var filter = company.filter((org) => {
//               return org.label.indexOf(inputValues) != -1
//             })
//             return filter
//           },
//         },
//         {
//           inputType: inputTypes.INPUT,
//           label: t.translate('words.name'),
//           key: 'name',
//           type: 'text',
//           width: '50%',
//           validation: 'required'
//         },
//         {
//           inputType: inputTypes.INPUT,
//           label: t.translate('words.description'),
//           key: 'description',
//           type: 'text',
//           validation: 'required',
//           width: '50%',
//         },
//         {
//           inputType: inputTypes.CHECKBOX,
//           label: t.translate('words.useDefault'),
//           key: 'useDefault',
//           type: 'text',
//           // validation: 'required',
//           width: '50%',
//         },
//         {
//           render: (
//             <Card className="srd-diagram">
//             <CardActions style={{ justifyContent: 'start' }}>
//               <Button><FontIcon iconClassName="mdi mdi-plus" onClick={async ()=> { 
//                 setEditType('State')
//                 setNodeDialog(true)
//                 setIsEditDialog(false)
//                }}></FontIcon></Button>
//               <Button><FontIcon iconClassName="mdi mdi-pencil"></FontIcon></Button>
//               <Button><FontIcon iconClassName="mdi mdi-selection" onClick={()=> { engine.zoomToFit() }}></FontIcon></Button>
//             </CardActions>
//             <CardContent>
//               <div className="mpk-bluepring-bg">
//                 <DiagramWidget diagramEngine={engine} />
//               </div>
//             </CardContent>
//             </Card>
//           )
//         }
//       ]}
//       onSubmit={async (data, callback) => {
//         try {
//           // if(data && data.company) delete data.company.value
//           delete data.company
//           data.formType = 'Global'
//           var res = {}
//           var diagramJson = model.serializeDiagram();
//           // data = {
//           //   // formType: 'Global',
//           //   states: [],
//           //   actions: [],
//           //   ...data
//           // }
//           data.jsonModel = JSON.stringify(diagramJson)
//           // diagramJson.nodes.forEach((d:DefaultNodeModel, i)=> {
//           //   data.states.push(d.extras)
//           // })
//           // diagramJson.links.forEach((d:DefaultLinkModel, i) => 2{
//           //   if(!d.extras.fromState) {
//           //     d.extras.fromState = {
//           //       id: d.source
//           //     }
//           //     d.extras.toState = {
//           //       id: d.target
//           //     }
//           //   }
//           //   data.actions.push(d.extras)
//           // })
//           var formData = Object.assign({}, data)
//           res = await service.createOne(formData)
//           callback(t.translate('sentences.tersimpan'), false, false, false)
//           navigationStore.redirectTo(`/product/company/${match.params.companyId}/${basePath}`)
//         } catch (e) {
//           ErrorService(e)
//           callback("", false, false, false)
//           return
//         }
//       }}
//     />
//     </>
//   )
// }

// export default inject('authStore', 'envStore', 'navigationStore')(observer(ApprovalFlow))
