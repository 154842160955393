import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpUserLogin.service'
import errorService from './../../services/errorService'
import { inject, observer } from 'mobx-react'
import {Button} from 'react-md'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import iziToast from 'izitoast'

const OpUserLogin = ({
  className       = '',
  showCommandbar  = true,
  modalStore,
  
}) => {
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  
  const baseId = 'mod-op-user-login'
  const basePath = 'logged-in'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function hapusExc(item) {
    try {
      let res = await service.deleteById([item.id])
      iziToast.success({
        message: `${t.translate('words.delete')} ${item.id} ${t.translate('words.prosesDone')}`
      })
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  return (
    <TableWrapper useFilter={false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="id"
      onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            try{
              params = {
                ...search,
                ...params
              }
              var user = JSON.parse(localStorage.getItem('user'))
              let getPage = null
              console.log(search)
              if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
                getPage = await service.get(params)
              } else {
                var companyId = user.company.id
                
                getPage = await service.findAll(params, companyId)
              }
              setData(getPage.data)
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              resolve(getPage)
            } catch(e){
              resolve()
              errorService(e)
            }
          })
        )
      }}
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.signIn`),
              key: 'login.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.company`),
              key: 'companyName.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.signIn'),
          searchable: true,
          sortable : true,
          key:'login',
          render: item => (item.login)
        },
        {
          label: t.translate('menu.companies'),
          searchable: true,
          sortable : true,
          key: 'companyName',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.companyName)
        },
        {
          label: t.translate('words.lastLogIn'),
          searchable: true,
          sortable : true,
          key: 'lastLogin',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.lastLogin).format('DD/MM/YYYY hh:mm:ss'))
        },
      ]}
      actions={[]}
      itemActions={[
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {hapus(item)}, true)
      ]}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpUserLogin))

