import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import Modal from "../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./LogEmail.service";
import bluebird from "bluebird";
import errorServiceGet from "../../services/errorServiceGet";
import moment from "moment";
import { toast } from "../../libs/react-mpk/services";
import { format } from "../../libs/react-mpk/services/number.service";
import { Chip, ListItem, List, Button } from "react-md";
import errorService from "../../services/errorService";
import { reject } from "lodash";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import DataForm from "../../libs/react-mpk/components/DataForm";
import AppMode from "../../AppMode";
import { param } from "react-dom-factories";
import ErrorService from "./../../services/errorService";
import SptService from "../DaftarSpt/DaftarSpt.service";
import LoadingOverlay from "react-loading-overlay";

const LogEmailInfo = ({
  className = "",
  showCommandbar = true,
  history,
  match,
  modalStore,
}) => {
  const basePath = `log-data-email-email`;
  const [data, setData] = useState({});
  const baseId = "mod-log-data-email-info";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [organization, setOrganization] = useState([]);

  let [search, setSearch] = useState({});

  useEffect(() => {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      setSearch(JSON.parse(localStorage.getItem(`${baseId}-saved-search`)));
    }
    async function initData() {
      try {
        let info = await service.getOne(match.params.id);
        setData(info.data);
      } catch (e) {
        errorService(e);
      }
    }
    initData();
    getOrganizations();
  }, []);

  const getOrganizations = async (values) => {
    var params = {
      page: 0,
      size: 200,
    };
    if (isNaN(values)) {
      params["field"] = "name";
      params["query"] = values;
    } else {
      params["field"] = "npwp";
      params["query"] = values;
    }
    let organization = await service.getOrganization(params);
    var getAll = organization.data;
    var organizationsFilter = [];
    try {
      getAll.map((og) => {
        og.label = og.npwp + " - " + og.name;
        if (og.certExists)
          organizationsFilter.push({
            value: og,
            label: og.label,
          });
      });
    } catch (e) {
      organization.data.map((d) => {
        d.label = d.npwp + " - " + d.name;
        organizationsFilter.push({
          value: d,
          label: d.label,
        });
      });
    }
    setOrganization(organizationsFilter);
    return organizationsFilter;
  };

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: "createdDate,DESC",
      };
      if (isNaN(values)) {
        params["name.contains"] = values;
      } else {
        params["npwp.contains"] = values;
      }
      let res = await SptService.getOrganizationOp(params);
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name;
        d.value = d.npwp;
      });
      setOrganization(res.data);
      return res.data;
    } catch (e) {
      ErrorService(e);
      return [];
    }
  };

  async function resendExc(item) {
    try {
      var proses = t.translate("words.kirimUlangEmail");
      var selected = JSON.parse(TableWrapper.getSelected(baseId));
      var selectedData = [];
      var selectedDn = [];
      var selectedLn = [];
      selected.map((d) => {
        selectedData.push(data[d]);
        if (data[d].type == "DN") selectedDn.push(data[d].id);
        if (data[d].type == "LN") selectedLn.push(data[d].id);
      });
      setPloading({
        loading: true,
        message: `${t.translate("words.prosesData")}`,
      });
      if (selectedDn.length > 0) var res = await service.postResendDn(selectedDn);
      if (selectedLn.length > 0) var res = await service.postResendLn(selectedLn);
      setPloading({ loading: false, message: `` });
      iziToast.success({
        title: proses,
        message: `${t.translate("words.proses")} ${proses} ${t.translate(
          "words.prosesDone"
        )}`,
      });
    } catch (e) {
      setPloading({ loading: false, message: `` });
      ErrorService(e);
    }
    try {
      var checkboxRoot = document.getElementById(
        `${baseId}-selectable-rows-root-checkbox`
      );
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute("aria-checked")) {
          checkboxRoot.click();
          checkboxRoot.click();
        } else {
          checkboxRoot.click();
        }
      }
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {}
  }

  async function resend(item) {
    modalStore.showConfirm({
      title: t.translate("words.kirimUlangEmail"),
      children: t.translate("words.confirmResendEmail"),
      onSubmit: (callback) => {
        resendExc();
        callback();
      },
    });
  }

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      ></LoadingOverlay>
      <TableWrapper
        useFilter={false}
        baseId="mod-log-data-email-info"
        title={t.translate("modules.logDataEmail.info")}
        className={className}
        selectable={true}
        defaultData={[]}
        showActionColumn={false}
        defaultSortBy="createdDate"
        isShowFilter={false}
        useFilter={false}
        onFetchData={(params) => {
          return new Promise(async (resolve, reject) => {
            try {
              params = {
                ...search,
                ...params,
              };
              delete params.auditDate;
              params.sort = "createdDate";
              let getPage = await service.getAll(match.params.id, params);
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              resolve(getPage);
            } catch (e) {
              resolve();
              errorService(e);
            }
          });
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName="mdi mdi-magnify"
            submitLabel={`${t.translate("words.cari")}`}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button
                    style={{ marginRight: 15 }}
                    onClick={() => {
                      setSearch({});
                      localStorage.removeItem(`${baseId}-saved-search`);
                      // DataForm.reset(`${baseId}-search`)
                      setTimeout(() => {
                        document
                          .getElementsByClassName("mdi-reload")[0]
                          .click();
                      }, 1000);
                    }}
                  >
                    {t.translate("words.hapus")}
                  </Button>
                ),
              },
            ]}
            onSubmit={(values, callback) => {
              if (values["createdDate.auditDate"])
                values["createdDate.auditDate"] = new Date(
                  values["createdDate.auditDate"]
                );
              setSearch(values);
              localStorage.setItem(
                `${baseId}-saved-search`,
                JSON.stringify(search)
              );
              setTimeout(() => {
                document.getElementsByClassName("mdi-reload")[0].click();
                callback("", false, false);
              }, 1000);
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div
                      className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                    >
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                ),
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.status`),
                key: "success.equals",
                type: "text",
                options: [
                  { label: "BERHASIL", value: "true" },
                  { label: "GAGAL", value: "false" },
                ],
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.no`),
                key: "no.contains",
                type: "text",
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: "npwp.in",
                labelKey: "label",
                valueKey: "label",
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues);
                      return options;
                    } else {
                      return organization;
                    }
                  } else {
                    if (inputValues.length % 2) {
                      var options = await getOrganizations(inputValues);
                      return options;
                    } else {
                      return organization;
                    }
                  }
                },
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.year`),
                key: "sptYear.equals",
                type: "text",
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month`),
                key: "sptMonth.equals",
                type: "text",
                options: [
                  { label: "1", value: "1" },
                  { label: "2", value: "2" },
                  { label: "3", value: "3" },
                  { label: "4", value: "4" },
                  { label: "5", value: "5" },
                  { label: "6", value: "6" },
                  { label: "7", value: "7" },
                  { label: "8", value: "8" },
                  { label: "9", value: "9" },
                  { label: "10", value: "10" },
                  { label: "11", value: "11" },
                  { label: "12", value: "12" },
                ],
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.rev`),
                key: "sptRev.equals",
                type: "text",
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.identity`),
                key: "identity.contains",
                type: "text",
                width: "100%",
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.email`),
                key: "email.contains",
                type: "text",
              },
            ]}
          />
        }
        columns={[
          {
            label: t.translate("words.status"),
            searchable: true,
            sortable: true,
            key: "success",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              if (item.success) {
                return (
                  <Chip style={{ background: "#8BC34A", color: "white" }}>
                    {(item.success + "").toUpperCase()}
                  </Chip>
                );
              } else {
                return (
                  <Chip style={{ background: "#F44336", color: "white" }}>
                    {(item.success + "").toUpperCase()}
                  </Chip>
                );
              }
            },
          },
          {
            label: t.translate("words.type"),
            searchable: true,
            sortable: true,
            key: "type",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.type,
          },
          {
            label: t.translate("words.no"),
            searchable: true,
            sortable: true,
            key: "no",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              if (item.no) {
                return item.no;
              } else {
                return "-";
              }
            },
          },
          {
            label: t.translate("words.sptNpwp"),
            searchable: true,
            sortable: true,
            key: "sptNpwp",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sptNpwp,
          },
          {
            label: t.translate("words.sptYear"),
            searchable: true,
            sortable: true,
            key: "sptYear",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sptYear,
          },
          {
            label: t.translate("words.sptMonth"),
            searchable: true,
            sortable: true,
            key: "sptMonth",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sptMonth,
          },
          {
            label: t.translate("words.revisiSpt"),
            searchable: true,
            sortable: true,
            key: "sptRev",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sptRev,
          },
          {
            label: t.translate("words.identity"),
            searchable: true,
            sortable: true,
            key: "identity",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.identity,
          },
          {
            label: t.translate("words.email"),
            searchable: true,
            sortable: true,
            key: "email",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.email,
          },
          {
            label: t.translate("words.sentMessage"),
            searchable: true,
            sortable: true,
            key: "sentMessage",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sentMessage,
          },
          {
            label: t.translate("words.reportMessage"),
            searchable: true,
            sortable: true,
            key: "reportMessage",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.reportMessage,
          },
          {
            label: t.translate("words.sendAt"),
            searchable: true,
            sortable: true,
            key: "sendAt",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sendAt,
          },
          {
            label: t.translate("words.deliveredAt"),
            searchable: true,
            sortable: true,
            key: "deliveredAt",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.deliveredAt,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.sendBy`),
            key: "sendBy.contains",
            type: "text",
            render: (item) => item.sendBy,
          },
          {
            label: t.translate("words.createdBy"),
            searchable: true,
            sortable: true,
            key: "createdBy",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdBy,
          },
          {
            label: t.translate("words.createdDate"),
            searchable: true,
            sortable: true,
            key: "createdDate",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdDate,
          },
          {
            label: t.translate("words.lastModifiedBy"),
            searchable: true,
            sortable: true,
            key: "lastModifiedBy",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.lastModifiedBy,
          },
        ]}
        actions={[]}
        itemActions={[]}
        showCommandbar={showCommandbar}
        showFilterePeriod={false}
        actions={[
          new TableWrapper.action(
            `${t.translate("words.kembali")}`,
            "mdi mdi-arrow-left",
            (item) => {
              if (AppMode.onPremise) {
                history.push(`/onpremise/log-email`);
              } else {
                if (window.location.href.indexOf("open") != -1) {
                  history.push(
                    `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/log-email`
                  );
                } else {
                  history.push(
                    `/product/company/${match.params.companyId}/log-email`
                  );
                }
              }
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.kirimUlangEmail")}`,
            "mdi mdi-email-send",
            (item) => {
              resend();
            },
            true
          ),
        ]}
      />
    </>
  );
};

export default inject("temporaryStore", "modalStore")(observer(LogEmailInfo));
