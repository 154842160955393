import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Pbk.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import { Chip, ListItem, Button } from 'react-md'
import ErrorService from '../../services/errorService'
import { reject } from 'lodash'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import DataForm from '../../libs/react-mpk/components/DataForm'
import AppMode from '../../AppMode'

const PbkHistory = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore
}) => {

  const basePath = `pbk`
  const [data, setData] = useState(({loading: false, content: {}}))
  const baseId = 'mod-pbk-history'
  let [search, setSearch] = useState({})

  async function hapusExc(item) {
    try {
      var r = await service.delete(item.id)

      if(r.data && r.data.status == 1) {
        window.open(r.data.data.url)
        iziToast.success({
          message : r.data.message
        })
      } else {
        iziToast.info({
          message : r.data.message
        })
      }
    } catch(e) {
      ErrorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  useEffect(() => {
    async function initData(){
      try {
          let history = await service.history(match.params.id)
          //console.log(data.content)
          setData({
            loading: false,
            content: history.data
          })
      } catch(e){
        ErrorService(e)
      }
    }
    initData()
  }, [])

  var actions = [
    new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
      if(AppMode.onPremise){
        history.push(`/onpremise/${basePath}`)
      } else {
        history.push(`/product/company/${match.params.companyId}/${basePath}`)
      }
    }, true)
  ]
  if(window.location.href.indexOf('open') != -1){
    actions = [
      new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
        if(AppMode.onPremise){
          history.push(`/onpremise/daftar-spt/open/${match.params.id}/${basePath}`)
        } else {
          history.push(`/product/company/${match.params.companyId}/daftar-spt/open/${match.params.id}/${basePath}`)
        }
      }, true)
    ]
  }

return (
  <>
    <TableWrapper useFilter={false}
        baseId = "mod-pbk-history"
        title = {t.translate('modules.pbk.history')}
        className = {className}
        defaultData ={[]}
        showActionColumn = {false}
        defaultSortBy = "auditDate"
        onFetchData = {(params) => {
            return (
                new Promise(async (resolve, reject) => {
                    try{
                      let getPage = await service.history(match.params.id)
                      getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                      resolve(getPage)
                    } catch(e){
                      resolve()
                      ErrorService(e)
                    }
                })
            )
        }}
        onFetchData = {(params) => {
            return (
                new Promise(async (resolve, reject) => {
                  try{
                    params = {
                      ...search,
                      ...params
                    }
                    let getPage = await service.history(match.params.id, params)
                    getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                    resolve(getPage)
                  } catch(e){
                    resolve()
                    ErrorService(e)
                  }
                })
            )
        }}
        sideHeader={
          <div></div>
        }
        // sideHeader={
        //   <DataForm 
        //     baseId={`${baseId}-search`}
        //       defaultData={search}
        //       submitIconClassName = 'mdi mdi-magnify'
        //       submitLabel={`${t.translate('words.cari')}`}
        //       additionalAction={[
        //         {
        //           show: true,
        //           render: ()=> (
        //             <Button style={{ marginRight: 15 }} onClick={()=> {
        //               setSearch({})
        //               localStorage.removeItem(`${baseId}-saved-search`)
        //               // DataForm.reset(`${baseId}-search`)
        //               setTimeout(()=> {
        //                 document.getElementsByClassName('mdi-reload')[0].click()
        //               }, 1000)
        //             }}>{t.translate('words.hapus')}</Button>
        //           )
        //         }
        //       ]}
        //       onSubmit={(values, callback)=> {
        //         if(values['createdDate.auditDate']) values['createdDate.auditDate'] = new Date(values['createdDate.auditDate'])
        //         setSearch(values)
        //         localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
        //         setTimeout(()=> {
        //           document.getElementsByClassName('mdi-reload')[0].click()
        //           callback("", false, false)
        //         }, 1000)
        //       }}
        //       definitions={[
        //         {
        //           render: (
        //             <div className="mpk-data-filter">
        //               <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
        //                 <p>{t.translate(`words.pencarian`)}</p>
        //               </div>
        //             </div>
        //           )
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.revisionId`),
        //           key: 'revisionId.equals',
        //           type: 'text'
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.type`),
        //           key: 'type.contains',
        //           type: 'text'
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.auditBy`),
        //           key: 'auditBy.contains',
        //           type: 'text'
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.auditDate`),
        //           key: 'auditDate.equals',
        //           type: 'date'
        //         },
        //     ]}
        //   />
        // }
        columns = {[
          {
              label : t.translate('words.revisionId'),
              searchable : false,
              sortable : true,
              key : 'revisionId',
              type : TableWrapper.dataTypes.NUMBER,
              render : (item) => (item.revisionId)
          },
          {
              label : t.translate('words.type'),
              searchable : true,
              sortable : true,
              key : 'type',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => (item.type)
          },
          {
              label : t.translate('words.auditBy'),
              searchable : true,
              sortable : true,
              key : 'auditBy',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => (item.auditBy)
          },
          {
              label : t.translate('words.auditDate'),
              searchable : true,
              sortable : true,
              key : 'auditDate',
              type : TableWrapper.dataTypes.STRING,
              render : (item) => (item.auditDate)
          },
          // {
          //     label : t.translate('words.data'),
          //     searchable : true,
          //     sortable : true,
          //     key : 'data',
          //     type : TableWrapper.dataTypes.STRING,
          //     render : (item) => (JSON.stringify(item.data))
          // },
          {
            label: t.translate('words.type'),
            searchable: true,
            sortable : true,
            key: 'type',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(JSON.stringify(item.data.type)){
                return <Chip>SSP</Chip>
              } else {
                return <Chip>PBK</Chip>
              }
            }
          },
          {
            label: t.translate('words.kodeObjekPajak'),
            searchable: true,
            sortable : true,
            key: 'kap',
            type: TableWrapper.dataTypes.STRING,
            render: item => (JSON.stringify(item.data.kap))
          },
          {
            label: t.translate('words.kodeJenisSetoran'),
            searchable: true,
            sortable : true,
            key: 'kjs',
            type: TableWrapper.dataTypes.STRING,
            render: item => (JSON.stringify(item.data.kjs))
          },
          {
            label: t.translate('words.nomorBuktiSetor'),
            searchable: true,
            sortable : true,
            key: 'no',
            type: TableWrapper.dataTypes.STRING,
            render: item => (JSON.stringify(item.data.no))
          },
          {
            label: t.translate('words.tanggal'),
            searchable: true,
            sortable : true,
            key: 'date',
            type: TableWrapper.dataTypes.STRING,
            render: item => (JSON.stringify(item.data.date))
          },
          {
            label: t.translate('words.pphDisetor'),
            searchable: true,
            sortable : true,
            key: 'total',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (JSON.stringify(item.data.total))
          },
          {
            label: t.translate('words.status'),
            searchable: true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(JSON.stringify(item.data.status) == "UPLOADING"){
                return <Chip style={{ background: "#FFC108", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
              } else if(JSON.stringify(item.data.status) == "COMPLETED"){
                return <Chip style={{ background: "#2096F3", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
              } else if(JSON.stringify(item.data.status) == "FINISH"){
                return <Chip style={{ background: "#8BC34A", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
               }else if(JSON.stringify(item.data.status) == "FAILED"){
                return <Chip style={{ background: "#F44336", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
              } else if(JSON.stringify(item.data.status) == "DELETED"){
                return <Chip style={{ background: "grey", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
              } else {
                return <Chip style={{ background: "black", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
              }
            }
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (JSON.stringify(item.data.message))
          },
          // {
          //   label: t.translate('words.createdBy'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'createdBy',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (JSON.stringify(item.data.createdBy))
          // },
          // {
          //   label: t.translate('words.createdDate'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'createdDate',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (JSON.stringify(item.data.createdDate))
          // },
          // {
          //   label: t.translate('words.lastModifiedBy'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'lastModifiedDate',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (JSON.stringify(item.data.lastModifiedDate))
          // },
          // {
          //   label: t.translate('words.lastModifiedDate'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'lastModifiedDate',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (JSON.stringify(item.data.lastModifiedDate))
          // },
        ]}
        actions = {[]}
        itemActions = {[]}
        showCommandbar={showCommandbar}
        showFilterePeriod={false}
        isShowFilter={false}
        useFilter={false}
        actions={actions}
    />     
  </>
)
}

export default inject('temporaryStore', 'modalStore')(observer(PbkHistory))