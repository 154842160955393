import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/ln'
let serviceObject = '/bp/object'
let serviceNegara = '/master/country'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/ln');
  serviceUrl = '/api/eunifikasi/bp/ln'
  serviceObject = '/api/eunifikasi/bp/object'
  serviceNegara = '/api/eunifikasi/master/country'
} else {
  service = new CrudService('/bp/ln');
}

service.exportCurrent = async(data, params)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data,
    config: {
      params: params
    }
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}

service.getTaxObjectCode = async(type)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceObject}?size=80&&type.equals=${type}`,
    })
}

service.getNegara = async(name)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceNegara}?size=200`,
    query: {
      'sort': 'name,ASC',
      'name.contains': name
    }
  })
}

service.history = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.postResend = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/resend`,
    data
  })
}

service.getReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/${id}`
  })
}

service.postReport = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/report`,
    data
  })
}

service.getRequestReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/report/${id}`
  })
}

service.getReupload = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/reupload/${id}`
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/summary`,
    query
  })
}

service.sendMail = async(data, email) => {
  return http.request({
    method : http.methods.POST,
    url : `${serviceUrl}/send`,
    data,
    config: {
      params: email
    }
  })
}


service.sendMailBulk = async(data, email)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/send/search`,
    data,
    config: {
      params: {
        email: email,
        ...data
      }
    }
  })
}

service.sendMailBulkOp = async(data, email)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/send/search`,
    query: data,
    // config: {
    //   params: data
    // }
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.reportBulk = async(data, all)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/search`,
    query: data
  })
}

service.reportBulkOp = async(data, all)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/search`,
    query: data
  })
}

export default service;